<app-heading-with-icon-and-action
  i18n-title
  title="Daten aus dem Call"
  icon="photo"
></app-heading-with-icon-and-action>

<div class="section-container">
  <app-info-text-with-icon
    i18n-text
    text="Hier sehen Sie alle Daten, die während des Anrufs ausgetauscht
      wurden."
  >
  </app-info-text-with-icon>

  <div class="scroll-row">
    <app-attachment-preview
      [menu]="menu"
      [attachmentList]="duringAppointmentAttachments"
    ></app-attachment-preview>
    <mat-menu #menu="matMenu">
      <ng-template matMenuContent let-data="data">
        <button (click)="editAttachmentNotes(data)" mat-menu-item>
          <mat-icon>description</mat-icon>
          <span i18n>Notiz bearbeiten</span>
        </button>
        <button
          *ngIf="data.annotationBlobUrl"
          (click)="downloadFile(data)"
          mat-menu-item
        >
          <mat-icon>download_for_offline</mat-icon>
          <span>Download Annotiert</span>
        </button>
        <button (click)="downloadFileOriginal(data)" mat-menu-item>
          <mat-icon>download</mat-icon>
          <span>Download Original</span>
        </button>
        <button (click)="deleteAttachment(data)" mat-menu-item>
          <mat-icon>delete</mat-icon>
          <span>Löschen</span>
        </button>
        <button
          *ngIf="data.type === CallAttachmentType.Image"
          (click)="openAttachment(data)"
          mat-menu-item
        >
          <mat-icon>visibility</mat-icon>
          <span>Ansehen</span>
        </button>
      </ng-template>
    </mat-menu>
  </div>
</div>

<mat-divider class="section-divider"></mat-divider>

<app-heading-with-icon-and-action
  i18n-title
  title="Aufgezeichnete Notizen"
  titleShort="Notizen"
  icon="description"
  [actionTitle]="editNotes ? textFinished : textEdit"
  [actionIcon]="editNotes ? 'done' : 'edit'"
  (actionTriggered)="toggleFormCtrl()"
>
</app-heading-with-icon-and-action>

<div class="section-container">
  <app-info-text-with-icon
    i18n-text
    text="Hier sehen Sie die Notizen, welche während des Anrufs angefertigt wurden. Diese werden nicht mit Ihrem Gesprächspartner geteilt."
  ></app-info-text-with-icon>

  <div class="notes-container">
    <app-call-notes
      #notes
      [enabled]="editNotes"
      [inquiryId]="inquiryId"
    ></app-call-notes>
  </div>
</div>
