import { UpdateCompanyInformation } from '../../../../api/gen';

export class UpdateCompanySettingsRequest {
  displayName?: string | null;
  privacyUrl?: string | null;

  static toDto(
    request: UpdateCompanySettingsRequest
  ): UpdateCompanyInformation {
    return {
      displayName: request.displayName,
      privacyUrl: request.privacyUrl,
    } as UpdateCompanyInformation;
  }
}
