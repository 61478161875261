<div class="inquiry-details-header">
  <mat-icon>{{ icon }}</mat-icon>
  <span class="inquiry-details-header-text ns">{{ title }}</span>
  <span class="spacer"></span>
  <div *ngIf="menuTitle">
    <button
      mat-flat-button
      color="primary"
      [matMenuTriggerFor]="menu"
    >
      <mat-icon *ngIf="menuIcon">{{ menuIcon }}</mat-icon>
      <span>{{ menuTitle }}</span>
    </button>
  </div>
</div>

<mat-menu #menu="matMenu" xPosition="before">
  <ng-content select="[heading-menu-actions]"></ng-content>
</mat-menu>
