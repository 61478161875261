<div class="container">
  <app-notification-bar
    *ngIf="
      notificationBarVisible &&
      (notificationBarService.getNotificationBarVisibility() | async)
    "
  ></app-notification-bar>
  <app-menu-bar *ngIf="menubarVisible"></app-menu-bar>

  <mat-sidenav-container>
    <mat-sidenav [opened]="showSideNav" class="sidebar-container" mode="side">
      <div class="sidebar-stretch">
        <mat-nav-list>
          <mat-list-item
            [routerLinkActive]="'is-nav-item-active'"
            class="sidebar-item"
            routerLink="/scheduling"
            queryParamsHandling="preserve"
          >
            <mat-icon class="sidebar-item-icon" matListItemIcon
              >schedule</mat-icon
            >
            <span class="sidebar-item-title" matListItemTitle i18n
              >Termine</span
            >
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item
            [routerLinkActive]="'is-nav-item-active'"
            class="sidebar-item"
            routerLink="/company"
            queryParamsHandling="preserve"
          >
            <mat-icon matListItemIcon>apartment</mat-icon>
            <span class="sidebar-item-title" matListItemTitle i18n>Firma</span>
          </mat-list-item>
        </mat-nav-list>
      </div>
      <div class="bottom-section">
        <div>
          <mat-nav-list>
            <mat-divider></mat-divider>
            <mat-list-item
              *ngIf="roleService.isInRole(BackendRole.Manager) | async"
              [routerLinkActive]="'is-nav-item-active'"
              class="sidebar-item"
              routerLink="/employees"
              queryParamsHandling="preserve"
            >
              <mat-icon matListItemIcon>people</mat-icon>
              <span class="sidebar-item-title" matListItemTitle i18n
              >Mitarbeiter</span
              >
            </mat-list-item>
            <mat-list-item
              [routerLinkActive]="'is-nav-item-active'"
              class="sidebar-item"
              routerLink="/profile"
              queryParamsHandling="preserve"
            >
              <mat-icon matListItemIcon>account_circle</mat-icon>
              <span class="sidebar-item-title" matListItemTitle i18n>Profil</span>
            </mat-list-item>
          </mat-nav-list>
        </div>
        <div *ngIf="shouldInstall()">
          <button
            mat-flat-button
            class="install-btn"
            type="button"
            color="primary"
            (click)="installPWA()"
          >
            <mat-icon matListItemIcon>install_desktop</mat-icon>
            <span i18n>Installieren</span>
          </button>
        </div>
      </div>


    </mat-sidenav>

    <mat-sidenav-content>
      <div class="content">
        <router-outlet
          (activate)="onRouterOutletActivate($event)"
        ></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
