import { Injectable } from '@angular/core';
import {
  Invitation,
  InvitationLanguage,
  InvitationType,
} from '../../model/inquiry/invitation';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InvitationDataService {
  private static defaultInvitation = {
    language: InvitationLanguage.German,
    type: InvitationType.Default,
  } as Invitation;

  private readonly invitation = new BehaviorSubject<Invitation>(
    InvitationDataService.defaultInvitation
  );

  getCurrentInvitationData(): Observable<Invitation> {
    return this.invitation.asObservable();
  }

  setCurrentInvitationData(invitation: Invitation) {
    this.invitation.next(invitation);
  }
}
