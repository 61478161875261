import { InquirySummaryReportConfiguration as InquirySummaryReportConfig } from 'projects/helper-client/src/api/gen';
import { ReportOutputFormat } from './report-output-format';
import { ReportType } from './report-type';

export class InquirySummaryReportConfiguration {
  includeHeader?: boolean;
  includeCompanySettings?: boolean;
  includeInquiryInformation?: boolean;
  includeAttachments?: boolean;
  attachmentIdentifiersToInclude?: Array<string> | null;
  includeTimeline?: boolean;
  includeNotes?: boolean;
  templateIdentifier?: string | null;
  type?: ReportType;
  outputFormat?: ReportOutputFormat;

  static fromDto(
    dto: InquirySummaryReportConfig
  ): InquirySummaryReportConfiguration {
    return {
      includeHeader: dto.includeHeader,
      includeCompanySettings: dto.includeCompanySettings,
      includeInquiryInformation: dto.includeInquiryInformation,
      includeAttachments: dto.includeAttachments,
      attachmentIdentifiersToInclude: dto.attachmentIdentifiersToInclude,
      includeTimeline: dto.includeTimeline,
      includeNotes: dto.includeNotes,
      templateIdentifier: dto.templateIdentifier,
      type: dto.type,
      outputFormat: dto.outputFormat,
    } as InquirySummaryReportConfiguration;
  }
}
