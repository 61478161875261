import { AppointmentService as Api } from '../../../api/gen';
import { map, Observable } from 'rxjs';
import { VideoAppointment } from '../../model/appointment/video-appointment';
import { Injectable } from '@angular/core';
import { Attachment } from '../../model/attachment/attachment';

@Injectable({ providedIn: 'root' })
export class AppointmentService {
  constructor(private readonly appointmentService: Api) {}

  public finishAppointment(inquiryId: string): Observable<VideoAppointment> {
    return this.appointmentService
      .appointmentsInquiryIdFinishPost(inquiryId)
      .pipe(map((dto) => VideoAppointment.fromDto(dto)));
  }

  public getAttachments(
    inquiryId: string,
    includeInProcess: boolean
  ): Observable<Attachment[]> {
    return this.appointmentService
      .appointmentsInquiryIdAttachmentGet(inquiryId, includeInProcess)
      .pipe(map((x) => x.map((dto) => Attachment.fromDto(dto))));
  }

  public deleteAttachment(
    inquiryId: string,
    attachmentId: string
  ): Observable<any> {
    return this.appointmentService.appointmentsInquiryIdAttachmentAttachmentIdDelete(
      inquiryId,
      attachmentId
    );
  }

  public startPresentingAttachment(
    inquiryId: string,
    attachmentId: string
  ): Observable<any> {
    return this.appointmentService.appointmentsInquiryIdAttachmentPresentAttachmentIdPost(
      inquiryId,
      attachmentId
    );
  }

  public stopPresentingAttachment(inquiryId: string): Observable<any> {
    return this.appointmentService.appointmentsInquiryIdAttachmentPresentDelete(
      inquiryId
    );
  }
}
