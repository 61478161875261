import { StaticTemplateDto } from 'projects/helper-client/src/api/gen';

export class StaticTemplate {
  templateIdentifier?: string | null;
  displayName?: string | null;
  containerName?: string | null;
  fileName?: string | null;
  fileUrl?: string | null;
  tenantId?: string | null;

  static fromDto(dto: StaticTemplateDto): StaticTemplate {
    return {
      templateIdentifier: dto.templateIdentifier,
      displayName: dto.displayName,
      containerName: dto.containerName,
      fileName: dto.fileName,
      fileUrl: dto.fileUrl,
      tenantId: dto.tenantId,
    } as StaticTemplate;
  }
}
