<div class="start-appointment-container">
  <div class="number-input">
    <app-phone-number-input
      (phoneNumberChanged)="phoneNumberChanged($event)"
      (phoneNumberVerifiedChanged)="phoneNumberVerifiedChanged($event)"
      (enterKeyPressed)="onInviteClicked()"
      [phoneNumberDisplaySize]="phoneNumberDisplaySize"
      *ngIf="
        !(skipPhoneNumberFormatting | async);
        else phoneNumberInputWithoutFormatting
      "
    ></app-phone-number-input>
    <button
      *ngIf="!isLoading"
      class="send-button"
      (click)="onInviteClicked()"
      [disabled]="
        (skipPhoneNumberFormatting | async)
          ? !phoneNumberAlternativeControl.valid
          : !(phoneNumberVerified | async)
      "
      color="primary"
      mat-fab
    >
      <mat-icon>send</mat-icon>
    </button>
    <div *ngIf="isLoading" class="loading">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
  </div>
  <div class="skip-formatting-link-container">
    <span
      (click)="isLoading || onRemoveNumberFormattingClicked()"
      class="skip-formatting-link ns"
      >{{
        (skipPhoneNumberFormatting | async)
          ? skipPhoneNumberFormattingText
          : dontSkipPhoneNumberFormattingText
      }}</span
    >
    <mat-icon
      class="skip-formatting-icon"
      matTooltip="{{
        (skipPhoneNumberFormatting | async)
          ? skipPhoneNumberFormattingInfoText
          : dontSkipPhoneNumberFormattingInfoText
      }}"
      >info
    </mat-icon>
  </div>
  <div *ngIf="showError" class="error-container">
    <mat-icon>error</mat-icon>
    <span i18n>Einladung fehlgeschlagen. Bitte überprüfe die Handynummer.</span>
  </div>
</div>

<ng-template #phoneNumberInputWithoutFormatting>
  <mat-form-field
    class="phone-number-input-alternative"
    subscriptSizing="dynamic"
  >
    <mat-label i18n>Telefonnummer</mat-label>
    <input
      (input)="phoneNumberModelChanged()"
      [formControl]="phoneNumberAlternativeControl"
      matInput
      placeholder="+43 664 123 1 231"
      type="tel"
      [OnlyNumberAndSpace]="true"
    />
  </mat-form-field>
</ng-template>
