import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as markerjs2 from 'markerjs2';
import {
  AddAnnotation,
  AttachmentService,
} from 'projects/helper-client/src/api/gen';
import { filter } from 'rxjs';
import { CallAttachment } from '../../video-appointment/services/appointment-attachments.service';

@Component({
  selector: 'app-annotate-image',
  templateUrl: './annotate-image.component.html',
  styleUrls: ['./annotate-image.component.scss'],
})
export class AnnotateImageComponent implements OnChanges {
  @ViewChild('image') referenceImage: ElementRef;
  @ViewChild('editArea') editArea: ElementRef;

  @Input()
  public srcImage: CallAttachment;

  @Output()
  public finishedAnnotating = new EventEmitter<boolean>();
  protected isLandscape: boolean;
  private editState: markerjs2.MarkerAreaState;
  private markerArea: markerjs2.MarkerArea;
  private openTaskCount = 0;

  constructor(
    private readonly attachmentRepo: AttachmentService,
    private readonly snackBar: MatSnackBar
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.markerArea?.close();
    this.markerArea = null;
    this.isLandscape = false;
    this.editState = null;
  }

  public startAnnotating() {
    if (this.markerArea) return;

    this.checkImageSize();

    const target = this.referenceImage.nativeElement as HTMLImageElement;

    this.markerArea = new markerjs2.MarkerArea(target);

    this.markerArea.targetRoot = target.parentElement;
    this.markerArea.styles.styleSheetRoot = target.parentElement;

    this.markerArea.availableMarkerTypes = [
      markerjs2.FreehandMarker,
      markerjs2.ArrowMarker,
      markerjs2.FrameMarker,

      markerjs2.EllipseFrameMarker,

      markerjs2.CalloutMarker,
      markerjs2.TextMarker,

      markerjs2.LineMarker,
      markerjs2.MeasurementMarker,
    ];

    this.markerArea.renderAtNaturalSize = true;

    this.markerArea.styles.settings.hideToolbox = false;
    this.markerArea.settings.newFreehandMarkerOnPointerUp = true;

    this.markerArea.uiStyleSettings.redoButtonVisible = false;
    this.markerArea.uiStyleSettings.zoomButtonVisible = false;
    this.markerArea.uiStyleSettings.zoomOutButtonVisible = false;

    this.markerArea.uiStyleSettings.toolbarHeight = 48;
    this.markerArea.styles.addClass({
      name: 'test',
      localName: 'test',
      style: 'top: auto !important',
    });

    this.markerArea.addEventListener('close', (event: any) => {
      this.markerArea = null;
      this.finishedAnnotating.emit(false);
    });

    this.markerArea.addEventListener('render', (event: any) => {
      this.editArea.nativeElement.src = event.dataUrl;
      this.editState = event.state;

      this.openTaskCount++;
      this.toggleLoadingSnackbar();
      const dto = {
        attachmentId: this.srcImage.id,
        annotationState: JSON.stringify(event.state),
        data: event.dataUrl,
        extension: 'png',
      } as AddAnnotation;
      this.attachmentRepo
        .attachmentAttachmentIdAnnotationPost(dto.attachmentId, dto)
        .subscribe((x) => {
          this.markerArea = null;
          this.finishedAnnotating.emit(true);
          this.openTaskCount--;
          this.toggleLoadingSnackbar();
        });
    });
    // markerArea.styles.settings.zIndex = '9999';
    this.markerArea.show();

    if (this.editState) {
      this.markerArea.restoreState(this.editState);
    } else if (this.srcImage.annotated_path && !this.editState) {
      this.attachmentRepo
        .attachmentAttachmentIdAnnotationStateGet(this.srcImage.id)
        .pipe(filter((x) => !!x.stateJson))
        .subscribe((state) => {
          this.editState = JSON.parse(state.stateJson);
          this.markerArea.restoreState(this.editState);
        });
    }
  }

  private checkImageSize(): void {
    this.isLandscape = null;
    const element = this.referenceImage.nativeElement as HTMLImageElement;
    this.isLandscape = element.clientWidth > element.clientHeight;
  }

  private toggleLoadingSnackbar() {
    if (this.openTaskCount <= 0) {
      this.snackBar._openedSnackBarRef.dismiss();
    } else {
      this.snackBar.open($localize`Speichern...`);
    }
  }
}
