import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InquiryState } from 'projects/helper-client/src/api/gen';
import { BehaviorSubject, first, Subject, Subscription } from 'rxjs';
import { TabChipData } from '../../shared/tab-chip/inquiry-detail-component-chip';
import { InquiryDashboardSummary } from '../../model/inquiry/inquiry-dashboard-summary';
import { InquiryDashboardService } from '../../services/api/inquiry-dashboard.service';

@Component({
  selector: 'app-inquiry-details',
  templateUrl: './inquiry-details.component.html',
  styleUrls: ['./inquiry-details.component.scss'],
})
export class InquiryDetailsComponent implements OnInit {
  InquiryState = InquiryState;
  protected refresh$ = new Subject<void>();
  protected inquiryDetailsTabToShow = InquiryDetailsTab.CallData;
  protected InquiryDetailsTab = InquiryDetailsTab;
  protected inquiryId: string;
  protected inquiry$: BehaviorSubject<InquiryDashboardSummary>;
  protected currentNoteSubscription: Subscription;
  protected inquiryDetailsComponentChips: TabChipData[] = [
    new TabChipData(0, $localize`Daten aus dem Videocall`, true),
    new TabChipData(1, $localize`Anhänge`, false),
    new TabChipData(2, $localize`Chat`, false),
    new TabChipData(3, $localize`PDF Report`, false),
    new TabChipData(4, $localize`Verlauf`, false),
  ];
  private unsubscribe$ = new Subject<void>();
  private uploadObservable$: any;
  private deleteObservable$: any;

  constructor(
    private readonly dashboardService: InquiryDashboardService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly ref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(first()).subscribe((params) => {
      this.inquiryId = params['inquiryId'];
      this.refreshAll();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    if (this.uploadObservable$) {
      this.uploadObservable$.unsubscribe();
    }

    if (this.deleteObservable$) {
      this.deleteObservable$.unsubscribe();
    }
  }

  inquiryInformationComponentChipSelected(index: number) {
    this.inquiryDetailsComponentChips.map((inquiryDetailsComponent) => {
      if (inquiryDetailsComponent.index == index) {
        inquiryDetailsComponent.isSelected = true;
        this.inquiryDetailsTabToShow = index;
      } else {
        inquiryDetailsComponent.isSelected = false;
      }
    });
  }

  customerDataUpdated($event: any) {
    this.refreshInquiry();
  }

  private refreshInquiry(): void {
    this.dashboardService
      .getInquiryDetails(this.inquiryId)
      .pipe(first())
      .subscribe((data) => {
        if (this.inquiry$) {
          this.inquiry$.next(data);
        } else {
          this.inquiry$ = new BehaviorSubject(data);
        }
        this.ref.markForCheck();
      });
  }

  private refreshAll() {
    this.refreshInquiry();
    this.refresh$.next();
  }

  onCallCancelled() {
    this.refreshAll();
  }
}

export enum InquiryDetailsTab {
  CallData = 0,
  AfterCallAttachments = 1,
  Chat = 2,
  Report = 3,
  Timetable = 4,
}
