import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  InquiryDashboardService,
  TimelineEventType,
  TimelineStepDto,
} from '../../../../api/gen';
import { first, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-inquiry-details-timeline',
  templateUrl: './inquiry-details-timeline.component.html',
  styleUrls: [
    './inquiry-details-timeline.component.scss',
    '../inquiry-details-tabs.scss',
  ],
})
export class InquiryDetailsTimelineComponent implements OnInit, OnDestroy {
  @Input() refresh: Subject<void>;
  @Input() inquiryId: string;
  timelineExpansionPanelOpen = false;
  protected timeline: TimelineStepDto[];
  private unsubscribe$ = new Subject<void>();

  constructor(
    private readonly dashboardService: InquiryDashboardService,
    private readonly ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.refreshTimeline();
    this.refresh.pipe(takeUntil(this.unsubscribe$)).subscribe((_) => {
      this.refreshTimeline();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.complete();
  }

  protected getIconForEvent(type: TimelineEventType): string {
    switch (type) {
      case TimelineEventType.InquiryCreated:
      case TimelineEventType.InstantInquiryCreated:
        return 'send';
      case TimelineEventType.AppointmentSuggested:
        return 'event_upcoming';
      case TimelineEventType.AppointmentAccepted:
        return 'event_available';
      case TimelineEventType.Canceled:
        return 'close';
      case TimelineEventType.CallFinished:
        return 'call_end';
      case TimelineEventType.Expired:
        return 'history_toogle_off';
      case TimelineEventType.Finished:
        return 'done';
      default:
        return 'question_mark';
    }
  }

  protected getTitleForEvent(type: TimelineEventType): string {
    switch (type) {
      case TimelineEventType.InquiryCreated:
        return $localize`Anfrage erstellt`;
      case TimelineEventType.InstantInquiryCreated:
        return $localize`Sofortbesprechung erstellt`;
      case TimelineEventType.AppointmentSuggested:
        return $localize`Termin vorgeschlagen`;
      case TimelineEventType.AppointmentAccepted:
        return $localize`Termin akzeptiert`;
      case TimelineEventType.Canceled:
        return $localize`Anfrage abgebrochen`;
      case TimelineEventType.CallFinished:
        return $localize`Anruf beendet`;
      case TimelineEventType.Expired:
        return $localize`Anfrage abgelaufen`;
      case TimelineEventType.Finished:
        return $localize`Anfrage abgeschlossen`;
      default:
        return $localize`Unbekannt`;
    }
  }

  private refreshTimeline(): void {
    this.timeline = null;
    this.dashboardService
      .contactInquiryDashboardDetailsInquiryIdTimelineGet(this.inquiryId)
      .pipe(first())
      .subscribe((data) => {
        this.timeline = data.timeline.reverse();
        this.ref.markForCheck();
      });
  }
}
