import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ProfileService } from '../../services/api/profile-service';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from '../../authentication/authentication.service';

@Component({
  selector: 'app-manage-subscription',
  templateUrl: './manage-subscription.component.html',
  styleUrls: ['./manage-subscription.component.scss'],
  providers: [ProfileService],
})
export class ManageSubscriptionComponent {
  public pricingTableId: string;
  public publishableKey: string;
  public clientReference: BehaviorSubject<string>;
  public customerEmail: string;

  constructor(
    authService: AuthenticationService,
    profileService: ProfileService
  ) {
    this.pricingTableId = environment.stripePricingTableId;
    this.publishableKey = environment.stripePublishableKey;
    authService.getUserIdentifier().then((identifier) => {
      this.clientReference = new BehaviorSubject<string>(identifier);
    });

    profileService.getProfile().subscribe((e) => {
      if (e.email) {
        this.customerEmail = e.email;
      }
    });
  }
}
