import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, filter, first, map, mergeMap } from 'rxjs';
import { VideoChatService } from '../services/videochat.service';
import { InquiryService } from '../../services/api/inquiry.service';
import { Inquiry } from '../../model/inquiry/inquiry';
import { AnimationOptions } from 'ngx-lottie';
import { InvitationStatus } from '../services/invitation-status.service';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss'],
  providers: [InquiryService],
})
export class InvitationComponent implements OnInit {
  private shareable_link_animation_path =
    'assets/animations/shareable-link.json';

  protected shareableLinkAnimationConfig: AnimationOptions = {
    path: this.shareable_link_animation_path,
    loop: false,
  };

  protected inquiry$: BehaviorSubject<Inquiry>;
  constructor(
    private readonly videoChatService: VideoChatService,
    private readonly inquiryService: InquiryService
  ) {
    this.inquiry$ = new BehaviorSubject<Inquiry>(null);
  }

  ngOnInit() {
    this.videoChatService.activeRoom$
      .pipe(
        filter((x) => !!x.data.inquiryIdentifier),
        map((x) => x.data.inquiryIdentifier),
        first(),
        mergeMap((inquiryId) => this.inquiryService.getInquiry(inquiryId))
      )
      .subscribe((inquiry) => {
        this.inquiry$.next(inquiry);
      });
  }

  protected readonly InvitationStatus = InvitationStatus;
}
