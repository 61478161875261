import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { filter, map, Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EmployeeSignalingService {
  private hubConnection: signalR.HubConnection;
  private messageStream = new Subject<Message>();

  constructor() {}

  private async connectToHub() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.apiPath + '/employee-hub')
      .withAutomaticReconnect()
      .build();
    await this.hubConnection.start();
    this.addEmployeeListener();
  }

  public joinEmployeeGroup(externalEmployeeIdentifier: string) {
    this.connectToHub().then(() => {
      this.hubConnection
        .invoke(RemoteMethods.CONNECT_EMPLOYEE, externalEmployeeIdentifier)
        .catch(console.error);
    });
  }

  public closeConnection() {
    this.hubConnection?.stop();
  }

  public onMessage(type: EmployeeMessageType): Observable<any> {
    return this.messageStream.asObservable().pipe(
      filter((x) => x.type === type),
      map((x) => x.data)
    );
  }

  private addEmployeeListener(): void {
    this.hubConnection.on(LocalMethods.EMPLOYEE_CHANGED, (data) => {
      this.messageStream.next(
        new Message(EmployeeMessageType.EmployeeChanged, data)
      );
    });
    this.hubConnection.on(LocalMethods.EMPLOYEE_QUOTA_CHANGED, (data) => {
      this.messageStream.next(
        new Message(EmployeeMessageType.EmployeeQuotaChanged, data)
      );
    });
  }
}

export enum EmployeeMessageType {
  EmployeeChanged,
  EmployeeQuotaChanged,
}

class Message {
  constructor(public type: EmployeeMessageType, public data: any) {}
}

class LocalMethods {
  public static get EMPLOYEE_CHANGED(): string {
    return 'notifyEmployeeChanged';
  }

  public static get EMPLOYEE_QUOTA_CHANGED(): string {
    return 'notifyEmployeeQuotaChanged';
  }
}

class RemoteMethods {
  public static get DISCONNECT_EMPLOYEE(): string {
    return 'DisconnectEmployee';
  }
  public static get CONNECT_EMPLOYEE(): string {
    return 'ConnectEmployee';
  }
}
