import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { filter, map, Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationCenterSignalingService {
  private hubConnection: signalR.HubConnection;
  private messageStream = new Subject<Message>();

  constructor() {}

  public joinGroup(tenantId: string, userId: string) {
    this.connectToHub().then(() => {
      this.hubConnection
        .invoke(RemoteMethods.connectTenantAndUserIdentifier, tenantId, userId)
        .catch(console.error);
    });
  }

  public closeConnection() {
    this.hubConnection?.stop();
  }

  public onMessage(type: NotificationCenterMessageType): Observable<any> {
    return this.messageStream.asObservable().pipe(
      filter((x) => x.type === type),
      map((x) => x.data)
    );
  }

  private async connectToHub() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.apiPath + '/notification-center-hub')
      .withAutomaticReconnect()
      .build();
    await this.hubConnection.start();
    this.addNotificationCenterListener();
  }

  private addNotificationCenterListener(): void {
    this.hubConnection.on(LocalMethods.notificationCreated, (data) => {
      this.messageStream.next(
        new Message(NotificationCenterMessageType.NotificationCreated, data)
      );
    });
  }
}

export enum NotificationCenterMessageType {
  NotificationCreated,
}

class Message {
  constructor(public type: NotificationCenterMessageType, public data: any) {}
}

class LocalMethods {
  public static get notificationCreated(): string {
    return 'notificationCreated';
  }
}

class RemoteMethods {
  public static get connectTenantAndUserIdentifier(): string {
    return 'ConnectTenantAndUserIdentifier';
  }
  public static get disconnectTenantAndUserIdentifier(): string {
    return 'DisconnectTenantAndUserIdentifier';
  }
}
