import { Injectable } from '@angular/core';
import { StaticTemplateService as StaticTemplateApi } from 'projects/helper-client/src/api/gen';
import { StaticTemplate } from '../../model/report/static-template';
import { Observable, map } from 'rxjs';

@Injectable()
export class StaticTemplateService {
  public static InquirySummaryIdentifier =
    '2c54e79c-d9b0-43c8-a924-3f14033fc009';

  constructor(private readonly staticTemplateService: StaticTemplateApi) {}

  getStaticTemplateByIdentifier(
    identifier: string
  ): Observable<StaticTemplate> {
    return this.staticTemplateService
      .staticTemplateIdentifierGet(identifier)
      .pipe(map((dto) => StaticTemplate.fromDto(dto)));
  }
}
