import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CameraComponent } from './shared/camera/camera.component';
import { ParticipantsComponent } from './shared/participants/participants.component';
import { DeviceSelectComponent } from './settings/device-select.component';
import { SettingsComponent } from './settings/settings.component';
import { VideoAppointmentRoutingModule } from './video-appointment-routing.module';
import { VideoAppointmentComponent } from './video-appointment.component';

import { CallCtrlComponent } from './landscape/call-ctrl/call-ctrl.component';

import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AngularSplitModule } from 'angular-split';
import { AnnotationsModule } from '../annotations/annotations.module';
import { AppointmentAttachmentsComponent } from './landscape/appointment-attachments/appointment-attachments.component';
import { CallPermissionInformationComponent } from './call-permission-information/call-permission-information.component';
import { PresentImageComponent } from './shared/content/present-image/present-image.component';
import { ConnectionErrorDialogComponent } from './dialogs/connection-error-dialog/connection-error-dialog.component';
import { DevicesForbiddenDialogComponent } from './dialogs/devices-forbidden-dialog/devices-forbidden-dialog.component';
import { DisconnectedDialogComponent } from './dialogs/disconnected-dialog/disconnected-dialog.component';
import { EndCallDialogComponent } from './dialogs/end-call-dialog/end-call-dialog.component';
import { OcrResultDialogComponent } from './dialogs/ocr-result-dialog/ocr-result-dialog.component';
import { PermissionInformationDialogComponent } from './dialogs/permission-information-dialog/permission-information-dialog.component';
import { SettingDialogComponent } from './dialogs/setting-dialog/setting-dialog.component';
import { DeactivateGuardService } from './services/deactivate-guard.service';
import { SyncService } from './services/sync.service';
import { VideochatContentService } from './services/videochat-content.service';
import { AudioVisualizationComponent } from './settings/audio-visualization/audio-visualization.component';
import { SideBarComponent } from './landscape/side-bar/side-bar/side-bar.component';

import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatStepperModule } from '@angular/material/stepper';
import { SharedModule } from '../shared/shared.module';
import { AddAttachmentButtonComponent } from './landscape/appointment-attachments/add-attachment-button/add-attachment-button.component';
import { InquiryInfosComponent } from './inquiry-infos/inquiry-infos.component';
import { InvitationStatusComponent } from './invitation-status/invitation-status.component';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';
import { DiagnosticsComponent } from './diagnostics/diagnostics.component';
import { UserStatsCardComponent } from './diagnostics/user-stats-card/user-stats-card.component';
import { PictureOverlayComponent } from './picture-overlay/picture-overlay.component';
import { DeviceTroubleComponent } from './diagnostics/device-trouble/device-trouble.component';
import { PermissionService } from './services/permission.service';
import { LandscapeAppointmentComponent } from './landscape/landscape-appointment/landscape-appointment.component';
import { PortraitAppointmentComponent } from './portrait/portrait-appointment/portrait-appointment.component';
import { MoveHandOverlayComponent } from './shared/move-hand-overlay/move-hand-overlay.component';
import { PortraitCallCtrlComponent } from './portrait/portrait-call-ctrl/portrait-call-ctrl.component';
import { PortraitAttachmentListComponent } from './portrait/portrait-attachment-list/portrait-attachment-list.component';
import { AttachmentPanelComponent } from './portrait/attachment-panel/attachment-panel.component';
import { MatBadgeModule } from '@angular/material/badge';
import { NewAttachmentPreviewComponent } from './new-attachment-preview/new-attachment-preview.component';
import { CameraSettingsPreviewComponent } from './settings/camera-settings-preview/camera-settings-preview.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { InvitationComponent } from './invitation/invitation.component';
import { ChatModule } from '@mad-tech/aidar-live-chat';

// load `lottie-web` on demand
export function playerFactory() {
  return import(
    /* webpackChunkName: 'lottie-web' */ 'lottie-web/build/player/lottie_svg'
  );
}

@NgModule({
  declarations: [
    VideoAppointmentComponent,
    CameraComponent,
    ParticipantsComponent,
    SettingsComponent,
    DeviceSelectComponent,
    CallCtrlComponent,
    SettingDialogComponent,
    AudioVisualizationComponent,
    DisconnectedDialogComponent,
    AppointmentAttachmentsComponent,
    EndCallDialogComponent,
    PresentImageComponent,
    SideBarComponent,
    ConnectionErrorDialogComponent,
    CallPermissionInformationComponent,
    PermissionInformationDialogComponent,
    DevicesForbiddenDialogComponent,
    OcrResultDialogComponent,
    AddAttachmentButtonComponent,
    InquiryInfosComponent,
    InvitationComponent,
    InvitationStatusComponent,
    DiagnosticsComponent,
    UserStatsCardComponent,
    PictureOverlayComponent,
    DeviceTroubleComponent,
    LandscapeAppointmentComponent,
    PortraitAppointmentComponent,
    MoveHandOverlayComponent,
    PortraitCallCtrlComponent,
    PortraitAttachmentListComponent,
    AttachmentPanelComponent,
    NewAttachmentPreviewComponent,
    CameraSettingsPreviewComponent,
  ],
  providers: [
    DeactivateGuardService,
    SyncService,
    VideochatContentService,
    DatePipe,
    PermissionService,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    VideoAppointmentRoutingModule,
    MatDialogModule,
    MatSelectModule,
    MatSnackBarModule,
    MatMenuModule,
    MatSidenavModule,
    AnnotationsModule,
    MatStepperModule,
    MatTabsModule,
    AngularSplitModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatTableModule,
    ClipboardModule,
    MatListModule,
    MatCardModule,
    SharedModule,
    LottieModule.forRoot({ player: playerFactory }),
    LottieCacheModule.forRoot(),
    MatBadgeModule,
    MatExpansionModule,
    ChatModule,
  ],
  exports: [PictureOverlayComponent],
})
export class VideoAppointmentModule {}
