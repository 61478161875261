import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CompanySettingsService } from '../../services/api/company.service';
import { InvitationDataService } from '../services/invitation-data.service';
import {
  Invitation,
  InvitationLanguage,
  InvitationMethod,
  InvitationType,
} from '../../model/inquiry/invitation';

@Component({
  selector: 'app-appointment-invitation-text',
  templateUrl: './appointment-invitation-text.component.html',
  styleUrls: ['./appointment-invitation-text.component.scss'],
})
export class AppointmentInvitationTextComponent {
  private readonly invitationLanguageStorageKey =
    'blankmile-appointment-invitation-language';

  protected selectedLanguage: string = 'german';
  protected isLoading$ = new BehaviorSubject(true);
  protected invitationText$ = new BehaviorSubject('');
  protected isGerman$ = new BehaviorSubject(true);
  protected panelOpenState: boolean = false;
  protected companyDisplayName: string;

  constructor(
    private readonly companyService: CompanySettingsService,
    private readonly invitationDataService: InvitationDataService
  ) {
    this.selectedLanguage =
      localStorage.getItem(this.invitationLanguageStorageKey) ?? 'german';
    this.isGerman$.next(this.selectedLanguage === 'german');
    this.companyService.getCompanySettings().subscribe((company) => {
      this.companyDisplayName = company.displayName;
      this.updateInvitationText(this.getInvitationText(company.displayName));
      this.isLoading$.next(false);
    });
  }

  private getInvitationText(companyDisplayName: string): string {
    if (this.isGerman$.value) {
      return `
        Hallo! <br/>
        Du wurdest von ${companyDisplayName} zu einer Videofernhilfe eingeladen. <br />
        Link zum Termin: <br />
        <a class="chat-bubble-link" href="https://portal.blankmile.at/<id>"
          >https://portal.blankmile.at/&lt;id&gt;</a
        >`;
    }

    return `
        Hello! <br/>
        You were invited by ${companyDisplayName} for a video call. <br />
        Link to appointment: <br />
        <a class="chat-bubble-link" href="https://portal.blankmile.at/<id>"
          >https://portal.blankmile.at/&lt;id&gt;</a
        >`;
  }

  onSelectGerman(ev: MouseEvent) {
    ev.stopPropagation();
    localStorage.setItem(this.invitationLanguageStorageKey, 'german');
    this.isGerman$.next(true);
    this.updateInvitationText(this.getInvitationText(this.companyDisplayName));
  }

  onSelectEnglish(ev: MouseEvent) {
    ev.stopPropagation();
    localStorage.setItem(this.invitationLanguageStorageKey, 'english');
    this.isGerman$.next(false);
    this.updateInvitationText(this.getInvitationText(this.companyDisplayName));
  }

  private updateInvitationText(text: string) {
    // TODO: Later we should be able to set a custom text in here (maybe we also want to adapt the InvitationMethod)
    const invitation = {
      language: this.isGerman$.value
        ? InvitationLanguage.German
        : InvitationLanguage.English,
      type: InvitationType.Default,
      method: InvitationMethod.Sms,
    } as Invitation;

    this.invitationText$.next(text);
    this.invitationDataService.setCurrentInvitationData(invitation);
  }
}
