import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NavigationService } from '../../services/util/navigation.service';
import { InquiryOpen } from '../../model/inquiry/InquiryOpen';

@Component({
  selector: 'app-join-open-appointment',
  templateUrl: './join-open-appointment.component.html',
  styleUrls: ['./join-open-appointment.component.scss'],
})
export class JoinOpenAppointmentComponent implements OnInit {
  protected dataSource: MatTableDataSource<InquiryOpen> =
    new MatTableDataSource([]);

  protected displayedColumns: string[] = [
    'name',
    'scheduledFor',
    'assignedEmployeeDisplayName',
    'joinAppointment',
  ];

  constructor(
    private navigationService: NavigationService,
    private dialogRef: MatDialogRef<JoinOpenAppointmentComponent>,
    private cdr: ChangeDetectorRef,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) private data: { inquiries: InquiryOpen[] }
  ) {
    this.dataSource.data = [...data.inquiries];
    this.cdr.markForCheck();
  }

  ngOnInit(): void {}

  onJoinAppointmentClicked(inquiry: InquiryOpen) {
    this.navigationService.navigateToVideoAppointment(
      inquiry.inquiryIdentifier
    );
    this.dialogRef.close();
  }
}
