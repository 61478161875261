<div class="preview">
  <img
    #image
    [ngClass]="{
      'landscape-img': isLandscape === true,
      'portrait-img': isLandscape === false
    }"
    [src]="srcImage.path"
    class="origin-image"
    crossorigin="anonymous"
  />
  <img
    #editArea
    [ngClass]="{
      'landscape-img': isLandscape === true,
      'portrait-img': isLandscape === false
    }"
    [src]="srcImage.annotated_path || srcImage.path"
    class="overlay-image"
    crossorigin="anonymous"
  />
</div>
