import { NotificationValidationService as Api } from '../../../api/gen';
import { map, Observable } from 'rxjs';
import { PhoneNumberValidationResult } from '../../model/notification/validation/phone-number-validation-result';
import { Injectable } from '@angular/core';
import { ValidatePhoneNumberRequest } from '../../model/notification/validation/validate-phone-number-request';

@Injectable()
export class NotificationValidationService {
  constructor(private readonly notificationValidationService: Api) {}

  public validatePhoneNumber(
    phoneNumber: string
  ): Observable<PhoneNumberValidationResult> {
    const request = { phoneNumber: phoneNumber } as ValidatePhoneNumberRequest;
    return this.notificationValidationService
      .notificationValidatePhoneNumberPost(
        ValidatePhoneNumberRequest.toDto(request)
      )
      .pipe(map((dto) => PhoneNumberValidationResult.fromDto(dto)));
  }
}
