import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VideoAppointmentComponent } from './video-appointment/video-appointment.component';
import { Title } from '@angular/platform-browser';
import { SignupInvitationLandingComponent } from './signup/signup-invitation-landing/signup-invitation-landing.component';
import { SetupPlatformOverviewComponent } from './setup/setup-platform-overview/setup-platform-overview.component';
import { NotificationBarService } from './services/notification-bar.service';
import { LoginComponent } from './authentication/login/login.component';
import { AuthCallbackComponent } from './authentication/auth-callback/auth-callback.component';
import { RoleService } from './authentication/role.service';
import { BackendRole } from './authentication/backend-role';

interface BeforeInstallPromptEvent extends Event {
  prompt(): void;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  showSideNav = false;
  notificationBarVisible = false;
  menubarVisible = false;
  showReportsSubmenu: boolean;

  promptEvent: BeforeInstallPromptEvent;

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e: BeforeInstallPromptEvent) {
    e.preventDefault();
    this.promptEvent = e;
  }

  @HostListener('window:appinstalled', ['$event'])
  appinstalled(e: any) {
    // Needed as otherwise install btn is still visible after installation
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  constructor(
    public router: Router,
    private readonly route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private readonly titleService: Title,
    protected readonly notificationBarService: NotificationBarService,
    protected readonly roleService: RoleService
  ) {
    this.titleService.setTitle($localize`blankmile`);
  }

  ngOnInit(): void {
    this.processQueryParameters();
  }

  public onRouterOutletActivate(event: any) {
    this.processActivatedComponent(event);
    this.processQueryParameters();
    this.cdr.detectChanges();
  }

  private processActivatedComponent(event: any) {
    if (
      event instanceof VideoAppointmentComponent ||
      event instanceof SignupInvitationLandingComponent ||
      event instanceof SetupPlatformOverviewComponent ||
      event instanceof LoginComponent ||
      event instanceof AuthCallbackComponent
    ) {
      this.menubarVisible = false;
      this.notificationBarVisible = false;
      this.showReportsSubmenu = false;
      this.showSideNav = false;
    } else {
      this.menubarVisible = true;
      this.notificationBarVisible = true;
      this.showSideNav = true;
    }
  }

  private processQueryParameters() {
    if (this.route.snapshot.queryParams['hideMenubar'] === 'true') {
      this.menubarVisible = false;
    }

    if (this.route.snapshot.queryParams['hideSideNav'] === 'true') {
      this.showSideNav = false;
    }
  }

  protected readonly BackendRole = BackendRole;

  public installPWA() {
    this.promptEvent?.prompt();
  }

  public shouldInstall(): boolean {
    return false;
    // return !this.isRunningInPWA() && !!this.promptEvent;
  }

  private isRunningInPWA(): boolean {
    return ['fullscreen', 'standalone', 'minimal-ui'].some(
      (displayMode) =>
        window.matchMedia('(display-mode: ' + displayMode + ')').matches
    );
  }
}
