import {
  AfterViewInit,
  Component,
  ElementRef,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { LocalTrackCtrlService } from '../../services/local-track-ctrl.service';
import { LocalVideoTrack } from 'twilio-video';
import { DiagnosticsService } from '../../services/diagnostics/diagnostics.service';
import { distinct, map, Subject, takeUntil } from 'rxjs';
import { AidarPermissionStatus } from '../../services/permission.service';
import { CallCtrlService } from '../../services/call-ctrl.service';

@Component({
  selector: 'app-camera-settings-preview',
  templateUrl: './camera-settings-preview.component.html',
  styleUrls: ['./camera-settings-preview.component.scss'],
})
export class CameraSettingsPreviewComponent implements AfterViewInit {
  @ViewChild('preview') previewElement: ElementRef;

  private unsubscribe$ = new Subject();

  protected displayCameraPlaceholder = false;
  protected problemText = '';

  protected deviceAvailable = true;
  protected permissionGiven = true;
  protected cameraTurnedOn = true;

  protected toggleCameraButtonDisabled = false;

  constructor(
    private readonly callCtrlService: CallCtrlService,
    private readonly diagnosticsService: DiagnosticsService,
    private readonly localTrackCtrl: LocalTrackCtrlService,
    private readonly renderer: Renderer2
  ) {
    this.diagnosticsService.diagnosticsChanged$
      .pipe(
        takeUntil(this.unsubscribe$),
        map((x) => {
          return {
            localCameraDevicesAvailable: x.localCameraDevicesAvailable,
            localCameraPermissionStatus: x.localCameraPermissionStatus,
            localCameraTurnedOn: x.localCameraTurnedOn,
          };
        }),
        distinct()
      )
      .subscribe((x) => {
        this.deviceAvailable = x.localCameraDevicesAvailable;
        this.permissionGiven = AidarPermissionStatus.isGiven(
          x.localCameraPermissionStatus
        );
        this.cameraTurnedOn = x.localCameraTurnedOn;
        this.evaluateProblemText();
      });
  }

  ngAfterViewInit(): void {
    this.localTrackCtrl.videoTrackUpdated$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(this.handleNewVideoTrack);

    this.localTrackCtrl.getVideoTrack().then((x) => {
      this.handleNewVideoTrack(x);
    });
  }

  private handleNewVideoTrack(track: LocalVideoTrack) {
    const videoElement = track?.attach();
    if (videoElement) this.attachTrack(videoElement);
    if (!videoElement) this.displayCameraPlaceholder = true;
    this.evaluateProblemText();
  }

  private evaluateProblemText(): void {
    if (!this.deviceAvailable)
      this.problemText = $localize`Keine Kameras angeschlossen`;
    else if (!this.permissionGiven)
      this.problemText = $localize`Kamera-Berechtigung nicht erteilt`;
    else if (!this.cameraTurnedOn)
      this.problemText = $localize`Kamera deaktivert`;
    else {
      this.problemText = '';
    }
  }

  private attachTrack(videoElement: HTMLMediaElement): void {
    videoElement.style.transform = 'scale(-1,1)';
    videoElement.style.borderRadius = '50%';
    videoElement.style.objectFit = 'cover';
    this.renderer.setStyle(videoElement, 'height', '10rem');
    this.renderer.setStyle(videoElement, 'width', '10rem');
    this.renderer.appendChild(this.previewElement.nativeElement, videoElement);
  }

  protected turnOnCamera() {
    this.toggleCameraButtonDisabled = true;
    this.callCtrlService.toggleCamState().then((x) => {
      this.toggleCameraButtonDisabled = false;
    });
  }
}
