<div class="center-content">
  <mat-dialog-content class="dialog-content">
    <div class="content-section">
      <form [formGroup]="editInfoFg">
        <div class="dialog-header">
          <h2 class="dialog-title" mat-dialog-title i18n>Kundendaten</h2>
        </div>

        <div class="content-information">
          <div class="mat-field-container full-width">
            <mat-form-field appearance="outline">
              <mat-label i18n>Telefonnummer</mat-label>
              <input
                #phonenumber
                formControlName="phoneNumber"
                matInput
                type="tel"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="content-information">
          <div class="mat-field-container">
            <mat-form-field appearance="outline">
              <mat-label i18n>Vorname</mat-label>
              <input formControlName="firstName" matInput type="text" />
            </mat-form-field>
          </div>

          <div class="mat-field-container">
            <mat-form-field appearance="outline">
              <mat-label i18n>Nachname</mat-label>
              <input formControlName="lastName" matInput type="text" />
            </mat-form-field>
          </div>
        </div>

        <div class="content-information">
          <div class="mat-field-container full-width">
            <mat-form-field appearance="outline">
              <mat-label i18n>Email</mat-label>
              <input formControlName="email" matInput type="email" />
            </mat-form-field>

            <span
              *ngIf="(email.touched ) && email.errors?.['pattern']"
              class="validation-error-text"
              i18n
            >
              Email Format nicht korrekt (bsp.: beispiel&#64;firma.at)
            </span>
          </div>
        </div>
      </form>
    </div>

    <div class="actions">
      <button
        (click)="closeDialog()"
        [disabled]="isLoading"
        class="cancel-button-style"
        color="warn"
        mat-button
      >
        <mat-icon
          class="mat-icon-style icon-padding-right cancel-mat-icon-style"
          >close</mat-icon
        >
        <span>Abbrechen</span>
      </button>
      <button
        (click)="saveData()"
        [disabled]="isLoading || editInfoFg.invalid"
        class="send-button-style"
        color="primary"
        mat-raised-button
      >
        <mat-icon class="mat-icon-style icon-padding-right send-mat-icon-style"
          >save</mat-icon
        >
        <span>Speichern</span>
      </button>
    </div>
  </mat-dialog-content>
</div>
