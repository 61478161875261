import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotificationBarService {
  private isVisibleSubject$ = new BehaviorSubject<boolean>(false);
  private notificationSubject$ = new BehaviorSubject<Notification>(null);

  getNotificationBarVisibility(): Observable<boolean> {
    return this.isVisibleSubject$.asObservable();
  }

  getNotification(): Observable<Notification> {
    return this.notificationSubject$.asObservable();
  }

  setNotificationBarVisibility(visible: boolean) {
    this.isVisibleSubject$.next(visible);
  }

  setNotification(notification: Notification) {
    this.notificationSubject$.next(notification);
  }
}

export class Notification {
  constructor(text: string, type: NotificationType, closeable: boolean) {
    this.text = text;
    this.type = type;
    this.closeable = closeable;
  }

  text: string;
  type: NotificationType;
  closeable: boolean;
}

export enum NotificationType {
  Info,
  Warn,
  Error,
}
