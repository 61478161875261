import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, delay, distinctUntilChanged, skip, Subject } from 'rxjs';
import { AttachmentService } from '../../../services/api/attachment.service';

@Component({
  selector: 'app-edit-attachment-note-dialog',
  templateUrl: './edit-attachment-note-dialog.component.html',
  styleUrls: ['./edit-attachment-note-dialog.component.scss'],
  providers: [AttachmentService],
})
export class EditAttachmentNoteDialogComponent {
  public textForm = new FormGroup({
    notes: new FormControl(''),
  });
  protected apiKey = environment.editorApiKey;
  protected options: any;
  protected notesLoadingCount = 0;
  protected loadingCount$ = new Subject<number>();

  constructor(
    public dialogRef: MatDialogRef<EditAttachmentNoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditAttachmentDialogData,
    private readonly attachmentService: AttachmentService
  ) {
    this.refresh();
    this.textForm
      .get('notes')
      .valueChanges.pipe(skip(1), debounceTime(500), distinctUntilChanged())
      .subscribe((note) => {
        const insertedNote = note === '' ? null : note;
        this.loadingCount$.next(++this.notesLoadingCount);
        this.attachmentService
          .updateAttachmentNote(this.data.attachmentIdentifier, insertedNote)
          .pipe(delay(1000))
          .subscribe({
            next: () => {
              this.loadingCount$.next(--this.notesLoadingCount);
            },
            error: () => {
              this.loadingCount$.next(--this.notesLoadingCount);
            },
          });
      });

    this.options = {
      menubar: '',
      plugins: 'lists table',
      statusbar: false,
      language: 'de',
      resize: true,
      toolbar:
        'blocks | bold italic underline | bullist numlist | ' +
        'forecolor backcolor | table',
    };
  }

  public refresh(): void {
    this.loadingCount$.next(++this.notesLoadingCount);
    this.attachmentService
      .getAttachment(this.data.attachmentIdentifier)
      .subscribe((x) => {
        this.textForm.get('notes').setValue(x?.note ?? '');
        this.loadingCount$.next(--this.notesLoadingCount);
      });
  }

  onUpdateAttachmentNote() {
    this.dialogRef.close(true);
  }

  onResize() {}
}

export interface EditAttachmentDialogData {
  attachmentIdentifier: string;
  attachmentName: string;
}
