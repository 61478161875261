import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { Quota } from '../../model/Quota';

@Injectable({
  providedIn: 'root',
})
export class QuotaDataService {
  public employeeQuota: Observable<Quota>;
  private employeeQuotaSubject$: ReplaySubject<Quota>;

  constructor() {
    this.employeeQuotaSubject$ = new ReplaySubject<Quota>(1);
    this.employeeQuota = this.employeeQuotaSubject$.asObservable();
  }

  public updateQuota(quota: Quota) {
    this.employeeQuotaSubject$.next(quota);
  }
}
