<div class="container">
  <div class="new-inquiry">
    <mat-card>
      <mat-card-content>
        <div class="new-inquiry-content-container">
          <app-appointment-invitation-text></app-appointment-invitation-text>
          <app-start-appointment></app-start-appointment>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="history">
    <mat-card>
      <mat-card-content>
        <app-call-history-overview></app-call-history-overview>
      </mat-card-content>
    </mat-card>
  </div>
</div>
