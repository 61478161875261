<div class="container">
  <div #preview>
  </div>
  <div class="camera-placeholder" *ngIf="displayCameraPlaceholder">
    <mat-icon>videocam_off</mat-icon>
  </div>
  {{problemText}}
  <div *ngIf="!cameraTurnedOn">
    <button mat-button color="primary" [disabled]="toggleCameraButtonDisabled" (click)="turnOnCamera()" i18n>Einschalten</button>
  </div>
</div>
