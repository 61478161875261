import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import {
  MatSnackBar,
  MatSnackBarRef,
  TextOnlySnackBar,
} from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { StaticTemplateService } from '../../../services/api/static-template.service';
import { Report } from '../../../model/report/report';
import { ReportService } from '../../../services/api/report.service';
import { CreateInquirySummaryReportRequest } from '../../../model/report/request/create-inquiry-summary-report-request';
import { MatDialog } from '@angular/material/dialog';
import { RenameReportDialogComponent } from './rename-report-dialog/rename-report-dialog.component';
import { CreateCustomReportDialogComponent } from './create-custom-report-dialog/create-custom-report-dialog.component';
import { ReportType } from '../../../model/report/report-type';
import { ReportOutputFormat } from '../../../model/report/report-output-format';
import { constants } from '../../../../environments/constants';

@Component({
  selector: 'app-inquiry-details-report',
  templateUrl: './inquiry-details-report.component.html',
  styleUrls: [
    './inquiry-details-report.component.scss',
    '../inquiry-details-tabs.scss',
  ],
  providers: [StaticTemplateService, ReportService],
})
export class InquiryDetailsReportComponent implements OnInit, OnDestroy {
  @Input() refresh: Subject<void>;
  @Input() inquiryId: string;
  @ViewChild(MatSort) sort: MatSort;
  protected createReportEnabled = true;
  protected dataSource: MatTableDataSource<Report> = new MatTableDataSource([]);
  protected displayedColumns: string[] = [
    'fileName',
    'createdAt',
    'downloadUrl',
    'moreActions',
  ];
  private unsubscribe$ = new Subject<void>();

  constructor(
    private readonly reportService: ReportService,
    private readonly staticTemplateService: StaticTemplateService,
    private readonly ref: ChangeDetectorRef,
    private readonly snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    private readonly renderer: Renderer2
  ) {
    if (window.innerWidth < constants.dimension.$sm) {
      this.displayedColumns = ['fileName', 'downloadUrl'];
    }
  }

  ngOnInit() {
    this.refreshReports();
    this.refresh.pipe(takeUntil(this.unsubscribe$)).subscribe((_) => {
      this.refreshReports();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.complete();
  }

  onClickCreateReport() {
    this.queryInquirySummaryTemplate();
  }

  onDownloadReport(downloadUrl: string) {
    const link = this.renderer.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', downloadUrl);
    link.setAttribute('download', 'file.pdf');
    link.click();
    link.remove();
  }

  onDeleteReport(report: Report) {
    this.reportService.deleteReport(report.id).subscribe(() => {
      this.refreshReports();
      this.snackBar.open($localize`Report wurde gelöscht.`, $localize`Ok`, {
        duration: 3000,
      });
    });
  }

  onRenameReport(report: Report) {
    const dialogRef = this.dialog.open(RenameReportDialogComponent, {
      data: { report: report },
    });

    dialogRef.afterClosed().subscribe((_: Report) => {
      this.refreshReports();
    });
  }

  private refreshReports() {
    this.reportService.getReports(this.inquiryId).subscribe((values) => {
      this.dataSource.data = [...values];
      this.dataSource.sort = this.sort;
      this.ref.markForCheck();
    });
  }

  private queryInquirySummaryTemplate() {
    const creatingReportSnackBar = this.snackBar.open(
      $localize`Report wird erstellt...`
    );
    this.createReportEnabled = false;

    this.staticTemplateService
      .getStaticTemplateByIdentifier(
        StaticTemplateService.InquirySummaryIdentifier
      )
      .subscribe((template) => {
        this.createReport(creatingReportSnackBar, template.templateIdentifier);
      });
  }

  private createReport(
    snackbar: MatSnackBarRef<TextOnlySnackBar>,
    templateIdentifier: string
  ) {
    const request = {
      inquiryIdentifier: this.inquiryId,
      configuration: {
        includeHeader: true,
        includeCompanySettings: true,
        includeInquiryInformation: true,
        includeAttachments: true,
        includeTimeline: false,
        includeNotes: true,
        templateIdentifier: templateIdentifier,
        type: ReportType.InquirySummary,
        outputFormat: ReportOutputFormat.Pdf,
      },
    } as CreateInquirySummaryReportRequest;
    this.reportService.createInquirySummaryReport(request).subscribe((_) => {
      this.refreshReports();
      this.createReportEnabled = true;
      snackbar.dismiss();
    });
  }

  onClickCreateCustomReport() {
    const dialogRef = this.dialog.open(CreateCustomReportDialogComponent, {
      data: { inquiryId: this.inquiryId },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.refreshReports();
      }
    });
  }
}
