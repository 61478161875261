import { Component, Input } from '@angular/core';
import { Attachment } from '../data/attachment';

@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss'],
})
export class AttachmentComponent {
  @Input() attachment: Attachment;

  AttachmentState = AttachmentState;
}

export enum AttachmentState {
  UPLOADING,
  SUCCESSFUL,
  FAILURE,
  NONE,
}
