<mat-expansion-panel
  class="expansion-panel"
  (opened)="panelOpenState = true"
  (closed)="panelOpenState = false"
>
  <mat-expansion-panel-header class="expansion-panel-header">
    <mat-panel-title i18n> SMS Einladungssprache </mat-panel-title>
    <mat-panel-description>
      <div class="appointment-invitation-language-selector">
        <mat-button-toggle-group
          [value]="selectedLanguage"
          class="appointment-invitation-language-toggle-group"
          name="appointment-invitation-language"
          i18n-aria-label
          aria-label="Sprache der SMS Einladung"
        >
          <mat-button-toggle
            value="german"
            i18n
            (click)="onSelectGerman($event)"
            >Deutsch</mat-button-toggle
          >
          <mat-button-toggle
            value="english"
            i18n
            (click)="onSelectEnglish($event)"
            >Englisch</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </mat-panel-description>
  </mat-expansion-panel-header>

  <div class="appointment-invitation-container">
    <span class="appointment-invitation-info" i18n
      >Vorschau der SMS Einladung</span
    >
    <div class="chat-bubble tri-right btm-left round">
      <div class="chat-bubble-text">
        <div class="chat-loading-spinner" *ngIf="isLoading$ | async; else text">
          <mat-spinner diameter="40"></mat-spinner>
        </div>
        <ng-template #text>
          <p [innerHtml]="invitationText$ | async"></p>
        </ng-template>
      </div>
    </div>
  </div>
</mat-expansion-panel>
