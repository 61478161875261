import { Injectable } from '@angular/core';
import { NotificationCenterService as Api } from '../../../api/gen';
import { map, Observable } from 'rxjs';
import { Notification } from '../../model/notification-center/notification';
import { UpdateNotificationRequest } from '../../model/notification-center/request/update-notification-request';
import { NotificationCenterConnectionInfo } from '../../model/notification-center/notification-center-connection-info';

@Injectable()
export class NotificationCenterService {
  constructor(private readonly notificationCenterService: Api) {}

  public getNotificationsOfLastMonth(): Observable<Notification[]> {
    return this.notificationCenterService
      .notificationCenterNotificationMonthGet()
      .pipe(map((x) => x.map((dto) => Notification.fromDto(dto))));
  }

  public getNotificationCenterConnectionInfo(): Observable<NotificationCenterConnectionInfo> {
    return this.notificationCenterService
      .notificationCenterConnectionInfoGet()
      .pipe(map((dto) => NotificationCenterConnectionInfo.fromDto(dto)));
  }

  public updateNotification(
    request: UpdateNotificationRequest
  ): Observable<Notification> {
    return this.notificationCenterService
      .notificationCenterNotificationPatch(request)
      .pipe(map((dto) => Notification.fromDto(dto)));
  }
}
