<img
  id="attachmentImage"
  *ngIf="attachment.type === CallAttachmentType.Image"
  class="attachment-image"
  src="{{
    attachment.annotationBlobUrl
      ? attachment.annotationBlobUrl
      : attachment.blobUrl
  }}"
/>
