import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { InquiryService } from '../../../services/api/inquiry.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-confirm-delete-inquiry-dialog',
  templateUrl: './confirm-delete-inquiry-dialog.component.html',
  styleUrls: ['./confirm-delete-inquiry-dialog.component.scss'],
  providers: [InquiryService],
})
export class ConfirmDeleteInquiryDialogComponent {
  private readonly inquiryId: string;
  protected readonly isLoading$ = new BehaviorSubject(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly inquiryIdentifier: string,
    private readonly dialogRef: MatDialogRef<ConfirmDeleteInquiryDialogComponent>,
    private readonly snackBar: MatSnackBar,
    private readonly inquiryService: InquiryService
  ) {
    this.inquiryId = inquiryIdentifier;
  }

  onConfirmDeleteDialog() {
    this.isLoading$.next(true);

    this.inquiryService.softDeleteInquiry(this.inquiryId).subscribe({
      next: (result) => {
        this.dialogRef.close(result);
        this.isLoading$.next(false);
      },
      error: () => {
        this.isLoading$.next(false);
        this.snackBar.open(
          $localize`Konnte Termin nicht löschen`,
          $localize`Ok`,
          {
            duration: 3000,
          }
        );
      },
    });
  }
}
