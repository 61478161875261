import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Employee } from '../../model/employee/employee';
import { EmployeeType } from '../../../api/gen';
import { MatDialog } from '@angular/material/dialog';
import { EditContactDialogComponent } from '../dialogs/edit-contact-dialog/edit-contact-dialog.component';

@Component({
  selector: 'app-personal-information-contact',
  templateUrl: './personal-information-contact.component.html',
  styleUrls: ['./personal-information-contact.component.scss'],
})
export class PersonalInformationContactComponent {
  @Input('employee') employee: Employee;

  constructor(
    private readonly dialog: MatDialog,
    private readonly cdr: ChangeDetectorRef
  ) {}

  getRoleText(): string {
    if (this.employee == null) {
      return '-';
    }

    switch (this.employee.type) {
      case EmployeeType.Administrator:
        return $localize`Adminstrator`;
      case EmployeeType.Manager:
        return $localize`Manager`;
      case EmployeeType.Agent:
        return $localize`Benutzer`;
    }

    return $localize`Keine Rolle`;
  }

  showEditContactDialog() {
    const dialogRef = this.dialog.open(EditContactDialogComponent, {
      data: this.employee,
    });

    dialogRef.afterClosed().subscribe((employee: Employee) => {
      if (employee) {
        this.employee = employee;
        this.cdr.markForCheck();
      }
    });
  }
}
