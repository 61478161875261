import { Injectable } from '@angular/core';
import { BlobServiceClient } from '@azure/storage-blob';
import * as JSZip from 'jszip';
import { firstValueFrom, Observable } from 'rxjs';
import {
  AssociatedCallPeriod,
  AttachmentDto,
  AttachmentService,
  CreateAttachment,
} from '../../../api/gen';
import { environment } from '../../../environments/environment';
import { AttachmentState } from '../../scheduling/inquiry-details/attachments/attachment/attachment.component';
import { AttachmentWithState } from '../../scheduling/inquiry-details/attachments/data/attachment';
import { saveAs } from 'file-saver-es';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class AttachmentFileService {
  private static MAX_UPLOAD_SIZE = 30_000_000; // 28,6MiB max size (kestrel default limit)
  private readonly blobServiceClient: BlobServiceClient;

  constructor(
    private readonly attachmentService: AttachmentService,
    private readonly snackBar: MatSnackBar
  ) {
    this.blobServiceClient = new BlobServiceClient(environment.storagePath);
  }

  private static getFileNameWithoutExtension(filename: string) {
    return filename.substring(0, filename.lastIndexOf('.'));
  }

  private static getFileExtension(filename: string) {
    return filename.substring(filename.lastIndexOf('.') + 1, filename.length);
  }

  downloadAttachment(
    attachment: AttachmentDto,
    attachmentDownloaded: (attachment: AttachmentDto) => void
  ) {
    const fileName = `${attachment.fileName}.${attachment.extension}`;
    saveAs(attachment.annotationBlobUrl ?? attachment.blobUrl, fileName);
    attachmentDownloaded(attachment);
  }

  uploadAttachments(
    associatedCallPeriod: AssociatedCallPeriod,
    inquiryId: string,
    files: File[]
  ): Observable<AttachmentWithState> {
    return new Observable<AttachmentWithState>((observer) => {
      const uploadedAttachments = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file) {
          if (!this.validateFileSize(file)) {
            return;
          }
          const reader = new FileReader();
          reader.readAsDataURL(file);

          reader.onload = (e) => {
            if (e.target && e.target.result) {
              const fileName = file.name;
              const content = e.target.result.toString();
              const createThumbnail = !(file.type.match('image/*') == null);

              observer.next(
                new AttachmentWithState(
                  fileName,
                  null,
                  AttachmentState.UPLOADING
                )
              );

              this.uploadFile(
                associatedCallPeriod,
                inquiryId,
                fileName,
                content,
                createThumbnail
              )
                .then((attachment) => {
                  uploadedAttachments.push(attachment);
                  observer.next(
                    new AttachmentWithState(
                      fileName,
                      attachment,
                      AttachmentState.SUCCESSFUL
                    )
                  );

                  if (uploadedAttachments.length >= files.length) {
                    observer.complete();
                  }
                })
                .catch((_) => {
                  observer.next(
                    new AttachmentWithState(
                      fileName,
                      null,
                      AttachmentState.FAILURE
                    )
                  );
                  observer.error(
                    new FileAttachmentUploadError(
                      fileName,
                      `Error uploading file with name ${fileName}`
                    )
                  );
                });
            }
          };
        }
      }
    });
  }

  private validateFileSize(file: File): boolean {
    if (file.size > AttachmentFileService.MAX_UPLOAD_SIZE) {
      const maxUploadSizeMb = Math.floor(
        AttachmentFileService.MAX_UPLOAD_SIZE / 1_048_576
      );
      this.snackBar.open(
        $localize`Die Datei ${file.name} ist zu groß. Es sind Dateien mit maximal ${maxUploadSizeMb}MiB erlaubt.`,
        'Ok',
        {
          duration: 5_000,
        }
      );
      return false;
    }

    return true;
  }

  async downloadAttachmentsAsZip(
    resultFileName: string,
    attachments: AttachmentDto[],
    attachmentZipped: (attachment: AttachmentDto) => void
  ) {
    const zip = new JSZip();
    for (const attachment of attachments) {
      const containerClient = this.blobServiceClient.getContainerClient(
        attachment.containerName
      );
      const blobClient = containerClient.getBlobClient(
        `${attachment.inquiryIdentifier}/${attachment.fileName}`
      );

      const downloadBlockBlobResponse = await blobClient.download();
      const blob = await downloadBlockBlobResponse.blobBody;
      const fileName = `${attachment.fileName}.${attachment.extension}`;
      zip.file(fileName, blob);

      attachmentZipped(attachment);
    }

    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, resultFileName);
    });
  }

  fileListToArray(fileList: FileList): File[] {
    const files: File[] = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      files.push(file);
    }
    return files;
  }

  private async uploadFile(
    associatedCallPeriod: AssociatedCallPeriod,
    inquiryId: string,
    fileName: string,
    content: string,
    createThumbnail: boolean
  ): Promise<AttachmentDto> {
    const createAttachmentDto: CreateAttachment = {};
    createAttachmentDto.period = associatedCallPeriod;
    createAttachmentDto.inquiryIdentifier = inquiryId;
    createAttachmentDto.fileName =
      AttachmentFileService.getFileNameWithoutExtension(fileName);
    createAttachmentDto.extension =
      AttachmentFileService.getFileExtension(fileName);
    createAttachmentDto.data = content;
    createAttachmentDto.createThumbnail = createThumbnail;

    return await firstValueFrom(
      this.attachmentService.attachmentPost(createAttachmentDto)
    );
  }
}

export class FileAttachmentUploadError extends Error {
  affectedFileName: string;

  constructor(affectedFileName: string, message: string) {
    super(message);

    this.affectedFileName = affectedFileName;
  }
}
