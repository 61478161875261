import { Injectable } from '@angular/core';
import { NotesDocumentationService as Api } from '../../../api/gen';
import { map, Observable } from 'rxjs';
import { NotesDocumentation } from '../../model/notes/notes-documentation';
import { UpdateNotesDocumentationRequest } from '../../model/notes/request/update-notes-documentation-request';

@Injectable()
export class NotesDocumentationService {
  constructor(private readonly notesService: Api) {}

  public getNotesDocumentation(
    inquiryIdentifier: string
  ): Observable<NotesDocumentation | null> {
    return this.notesService
      .notesInquiryIdGet(inquiryIdentifier)
      .pipe(
        map((dto) => (dto != null ? NotesDocumentation.fromDto(dto) : null))
      );
  }

  public updateNotesDocumentation(
    inquiryId: string,
    request: UpdateNotesDocumentationRequest
  ): Observable<NotesDocumentation> {
    return this.notesService
      .notesInquiryIdPost(
        inquiryId,
        UpdateNotesDocumentationRequest.toBody(request)
      )
      .pipe(map((dto) => NotesDocumentation.fromDto(dto)));
  }
}
