<div
  class="container"
  [class.expanded]="expaned"
  (swipeup)="expand(true)"
  (swipedown)="expand(false)"
>
  <div class="expand-container ns" (click)="toggleExpanded()">
    <mat-icon>{{ expaned ? "expand_more" : "expand_less" }}</mat-icon>
    <div>
      <span i18n>Fotos, Notizen & </span><span
      [matBadgeHidden]="(unreadMessagesCount$ | async) <= 0"
      [matBadgeOverlap]="false"
      [matBadge]="unreadMessagesCount$ | async"
      i18n
    >Chat</span>
    </div>
  </div>
  <div class="call-controls">
    <button
      *ngIf="
        (permissionsGranted$ | async)?.isCamGiven();
        else noCameraPermission
      "
      (click)="toggleVideo()"
      mat-fab
      class="action"
    >
      <mat-icon>{{
          (isCameraEnabled$ | async) ? "videocam" : "videocam_off"
        }}
      </mat-icon>
    </button>

    <button
      *ngIf="
        (permissionsGranted$ | async)?.isMicGiven();
        else noMicrophonePermission
      "
      (click)="toggleMic()"
      mat-fab
      class="action"
    >
      <mat-icon>{{
          (isMicrophoneEnabled$ | async) ? "mic" : "mic_off"
        }}
      </mat-icon>
    </button>

    <button
      (click)="videoCtrlsEnabled && takePicture()"
      [class.action-disabled]="!videoCtrlsEnabled"
      mat-fab
      [matBadgeHidden]="(attachmentCount$ | async) <= 0"
      [matBadge]="attachmentCount$ | async"
      matBadgeOverlap="true"
      class="action photo-btn"
    >
      <mat-icon *ngIf="!pictureLoading" aria-hidden="false"
      >photo_camera
      </mat-icon
      >
      <mat-spinner
        *ngIf="pictureLoading"
        class="picture-spinner"
        [diameter]="25"
      ></mat-spinner>
    </button>

    <button
      [ngSwitch]="callCtrlService.subscribeToZoomState() | async"
      [disabled]="!videoCtrlsEnabled"
      [class.action-disabled]="!videoCtrlsEnabled"
      (click)="videoCtrlsEnabled && changeZoom()"
      mat-fab
      class="action"
    >
      <mat-icon *ngSwitchCase="ZoomState.Default" aria-hidden="false">
        zoom_in
      </mat-icon>
      <mat-icon *ngSwitchCase="ZoomState.Zoom1" aria-hidden="false">
        zoom_out
      </mat-icon>
      <mat-icon *ngSwitchDefault aria-hidden="false" class="disabled">
        zoom_in
      </mat-icon>
    </button>

    <button (click)="endCall()" mat-fab class="action end-call-btn">
      <mat-icon>call_end</mat-icon>
    </button>
  </div>
  <div class="attachments">
    <app-attachment-panel #attachmentPanel></app-attachment-panel>
  </div>
</div>

<ng-template #noCameraPermission>
  <button
    mat-fab
    [disabled]="true"
    class="action action-disabled">
    <mat-icon
      i18n-matTooltip
      matTooltip="Kamera Berechtigung nicht erteilt!"
      matTooltipPosition="right"
    >
      videocam_off
    </mat-icon>
  </button>
</ng-template>

<ng-template #noMicrophonePermission>
  <button
    mat-fab
    [disabled]="true"
    class="action action-disabled"
  >
    <mat-icon
      i18n-matTooltip
      matTooltip="Mikrofon Berechtigung nicht erteilt!"
      matTooltipPosition="right"
    >
      mic_off
    </mat-icon>
  </button>
</ng-template>
