import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { ConnectivtyBannerService } from './connectivty-banner.service';

// https://stackoverflow.com/questions/64981528/angular-material-snackbar-in-service
@Injectable()
export class ConnectivityInterceptorService implements HttpInterceptor {
  constructor(private bannerService: ConnectivtyBannerService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((x) => {
        if (x instanceof HttpResponse) {
          this.bannerService.requestSucceeded();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        // https://satyapriyamishra111.medium.com/angular-error-interceptor-4b102f938065
        if (error.error instanceof ErrorEvent) {
          // client side error
        } else {
          this.bannerService.requestFailed();
        }
        return throwError(error);
      })
    );
  }
}
