import { Component, Input } from '@angular/core';
import { Employee } from '../../model/employee/employee';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  InitiatedActionService,
  KeycloakInitiatedActionStatus,
} from '../../authentication/initiated-action.service';

@Component({
  selector: 'app-personal-information-authentication',
  templateUrl: './personal-information-authentication.component.html',
  styleUrls: ['./personal-information-authentication.component.scss'],
})
export class PersonalInformationAuthenticationComponent {
  @Input('employee') employee: Employee;

  constructor(
    private readonly initiatedActionService: InitiatedActionService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly snackBar: MatSnackBar
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      const actionStatus = params['kc_action_status'];
      this.showPasswordChangeActionInformation(actionStatus);
    });
  }

  onResetPassword() {
    this.initiatedActionService.resetPassword('/profile');
  }

  private showPasswordChangeActionInformation(actionStatus: string) {
    if (actionStatus === KeycloakInitiatedActionStatus.Success) {
      this.snackBar.open(
        $localize`Passwort wurde erfolgreich geändert.`,
        $localize`Ok`,
        {
          duration: 3_000,
        }
      );
    } else if (actionStatus === KeycloakInitiatedActionStatus.Cancelled) {
      this.snackBar.open(
        $localize`Änderung des Passworts wurde abgebrochen.`,
        $localize`Ok`,
        {
          duration: 3_000,
        }
      );
    } else if (actionStatus === KeycloakInitiatedActionStatus.Error) {
      this.snackBar.open(
        $localize`Fehler beim Ändern deines Passwortes.`,
        $localize`Ok`,
        {
          duration: 3_000,
        }
      );
    }
  }
}
