import { CreateUserFromValidationToken } from '../../../../api/gen';

export class CreateUserFromValidationTokenRequest {
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  password?: string | null;
  token?: string | null;

  static toDto(
    request: CreateUserFromValidationTokenRequest
  ): CreateUserFromValidationToken {
    return {
      firstName: request.firstName,
      lastName: request.lastName,
      phoneNumber: request.phoneNumber,
      password: request.password,
      token: request.token,
    } as CreateUserFromValidationToken;
  }
}
