<mat-tab-group
  (selectedTabChange)="tabChanged($event)"
  [dynamicHeight]="true"
  [selectedIndex]="activeIndex"
  mat-stretch-tabs
  preserveContent
>
  <mat-tab i18n-label label="Notizen">
    <app-call-notes
      [enabled]="true"
      [useMiniVersion]="true"
      *ngIf="inquiryId$ | async"
      [inquiryId]="inquiryId$ | async"
    ></app-call-notes>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span
        [matBadgeHidden]="(attachmentCount$ | async) <= 0"
        [matBadgeOverlap]="false"
        [matBadge]="attachmentCount$ | async"
        i18n
        >Dateien</span
      >
    </ng-template>
    <app-portrait-attachment-list
      class="content"
    ></app-portrait-attachment-list>
  </mat-tab>
  <mat-tab aria-labelledby="chat-tab" *ngIf="liveChatEnabled">
    <ng-template mat-tab-label>
      <span
        [matBadgeHidden]="(unreadMessagesCount$ | async) <= 0"
        [matBadgeOverlap]="false"
        [matBadge]="unreadMessagesCount$ | async"
        i18n
      >Chat</span
      >
    </ng-template>
    <app-live-chat *ngIf="inquiryId$ | async" [inquiryId]="inquiryId$ | async"></app-live-chat>
  </mat-tab>
</mat-tab-group>
