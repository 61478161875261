import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PhoneNumberValidationResult } from '../../../model/notification/validation/phone-number-validation-result';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { LineTypeIntelligenceType } from '../../../model/notification/validation/line-type-intelligence';
import { InquiryService } from '../../../services/api/inquiry.service';
import { CreateNewScheduledInquiryRequest } from '../../../model/inquiry/request/create-new-scheduled-inquiry';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InvitationDataService } from '../../services/invitation-data.service';
import { NavigationService } from '../../../services/util/navigation.service';
import { Invitation } from '../../../model/inquiry/invitation';

@Component({
  selector: 'app-phone-number-not-valid-dialog',
  templateUrl: './phone-number-not-valid-dialog.component.html',
  styleUrls: ['./phone-number-not-valid-dialog.component.scss'],
  providers: [InquiryService],
})
export class PhoneNumberNotValidDialogComponent {
  protected isLoading$ = new BehaviorSubject(false);
  protected errorReason$ = new BehaviorSubject('');
  constructor(
    @Inject(MAT_DIALOG_DATA)
    validationResult: PhoneNumberValidationResult,
    private readonly inquiryService: InquiryService,
    private readonly invitationDataService: InvitationDataService,
    private readonly snackBar: MatSnackBar,
    private readonly navigationService: NavigationService,
    private readonly dialogRef: MatDialogRef<PhoneNumberNotValidDialogComponent>
  ) {
    this.errorReason$.next(this.getErrorReason(validationResult));
  }

  // TODO: Notify dashboard that a new inquiry was created and update table data
  async createInquiryWithLink() {
    this.isLoading$.next(true);
    const request = {
      startInstant: true,
    } as CreateNewScheduledInquiryRequest;
    this.inquiryService.createNewInquiry(request).subscribe({
      next: (inquiry) => {
        this.navigationService.navigateToVideoAppointment(
          inquiry.inquiryIdentifier,
          false
        );
        this.dialogRef.close(true);
      },
      error: () => {
        this.isLoading$.next(false);
        this.snackBar.open(
          $localize`Konnte keinen Termin erstellen, bitte versuche es später erneut.`
        );
      },
    });
  }

  private getErrorReason(res: PhoneNumberValidationResult) {
    if (res.couldValidate === false || !res.validation) {
      return $localize`Nummer konnte nicht validiert werden.`;
    }

    // TODO: More specific error messages depending on validationErrors
    /*if (res.validation.validationErrors.length > 0) {
    }*/

    if (!res.validation.valid) {
      return $localize`Die Nummer wurde in einem ungültigen Format eingegeben oder existiert nicht.`;
    }

    if (
      res.validation.lineTypeIntelligence &&
      res.validation.lineTypeIntelligence.type !==
        LineTypeIntelligenceType.Mobile
    ) {
      return $localize`Die eingegebene Nummer ist keine Mobilrufnummer und kann deshalb keine SMS empfangen.`;
    }

    return $localize`Nummer konnte nicht validiert werden.`;
  }
}
