import { InquiryState } from 'projects/helper-client/src/api/gen';
import { InquiryDashboardSummary } from '../../model/inquiry/inquiry-dashboard-summary';
import { DashboardInformationService } from '../services/dashboard-information.service';
import { Employee } from '../../model/employee/employee';

export class InquirySummary {
  inquiryIdentifier: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  scheduledFor: string;
  appointmentConfirmed: boolean;
  appointmentDeclined: boolean;
  endedAt: string;
  assignedEmployeeIdentifier: string;
  assignedEmployeeDisplayName: string;
  duration: string;
  state?: InquiryState;
  inquiryCreatedAt: string;
  autoDeletionScheduledAt?: string | null;
  autoDeletedAt?: string | null;
  manuallyDeletedAt?: string | null;
  manuallyDeletedBy?: string | null;
  attachmentCount: number;
  employee: Employee;

  static fromInquiryDashboardSummary(
    summary: InquiryDashboardSummary
  ): InquirySummary {
    return {
      inquiryIdentifier: summary.inquiryIdentifier,
      firstName: summary.firstName,
      lastName: summary.lastName,
      email: summary.email,
      phoneNumber: summary.phoneNumber,
      scheduledFor: summary.appointmentScheduledFor,
      appointmentConfirmed: summary.scheduledForConfirmed,
      appointmentDeclined: summary.scheduledForDeclined,
      endedAt: summary.appointmentEndedAt,
      assignedEmployeeIdentifier: summary.employee.externalIdentifier,
      assignedEmployeeDisplayName:
        DashboardInformationService.getEmployeeDisplayName(summary.employee),
      duration: DashboardInformationService.calculateCallDuration(
        summary.appointmentScheduledFor,
        summary.appointmentEndedAt
      ),
      state: summary.state,
      inquiryCreatedAt: summary.inquiryCreatedAt,
      autoDeletionScheduledAt: summary.autoDeletionScheduledAt,
      autoDeletedAt: summary.autoDeletedAt,
      manuallyDeletedAt: summary.manuallyDeletedAt,
      manuallyDeletedBy: summary.manuallyDeletedBy,
      attachmentCount: summary.attachments.length,
      employee: summary.employee,
    } as InquirySummary;
  }
}

export interface InquiryStateData {
  value: InquiryState;
  displayName: string;
}

export interface EmployeeData {
  value: string;
  displayName: string;
}
