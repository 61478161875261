import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { filter, map, Observable, Subject } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SignalingService {
  private hubConnection: signalR.HubConnection;

  private messageStream = new Subject<Message>();

  constructor() {}

  private async connectToHub() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.apiPath + '/appointment-hub')
      .withAutomaticReconnect()
      .build();
    await this.hubConnection.start();
    this.addAppointmentListener();
  }

  public joinAppointmentGroup(inquiryId: string) {
    this.connectToHub().then(() => {
      this.hubConnection
        .invoke(RemoteMethods.JOIN_ROOM, inquiryId)
        .catch(console.error);
      // Trigger sync to fetch the current call state upon join/reload etc
      this.messageStream.next(new Message(MessageType.Sync, inquiryId));
    });
  }

  public closeConnection() {
    this.hubConnection?.stop();
  }

  public onMessage(type: MessageType): Observable<any> {
    return this.messageStream.asObservable().pipe(
      filter((x) => x.type === type),
      map((x) => x.data)
    );
  }

  private addAppointmentListener(): void {
    this.hubConnection.on(LocalMethods.SYNC, (data) => {
      this.messageStream.next(new Message(MessageType.Sync, data));
    });
    this.hubConnection.on(LocalMethods.SYNC_ATTACHMENTS, (data) => {
      this.messageStream.next(new Message(MessageType.Sync_Attachments, data));
    });
    this.hubConnection.on(LocalMethods.HANG_UP, (data) => {
      this.messageStream.next(new Message(MessageType.Hang_Up, data));
    });
    this.hubConnection.on(LocalMethods.IS_RECORDING, (data) => {
      this.messageStream.next(new Message(MessageType.Is_Recording, data));
    });
    this.hubConnection.on(LocalMethods.INVITATION_STATUS_CHANGED, (data) => {
      this.messageStream.next(
        new Message(MessageType.Invitation_Status_Changed, data)
      );
    });
    this.hubConnection.on(LocalMethods.PUBLISH_NEW_CHAT_MESSAGE, (data) => {
      this.messageStream.next(
        new Message(MessageType.Publish_New_Chat_Message, data)
      );
    });
  }
}

class Message {
  constructor(public type: MessageType, public data: any) {}
}

export enum MessageType {
  Sync,
  Sync_Attachments,
  Hang_Up,
  Is_Recording,
  Invitation_Status_Changed,
  Publish_New_Chat_Message,
}

class LocalMethods {
  public static get SYNC(): string {
    return 'sync';
  }

  public static get SYNC_ATTACHMENTS(): string {
    return 'sync_attachments';
  }

  public static get HANG_UP(): string {
    return 'hang_up';
  }

  public static get IS_RECORDING(): string {
    return 'is_recording';
  }

  public static get INVITATION_STATUS_CHANGED(): string {
    return 'invitation_status_changed';
  }

  public static get PUBLISH_NEW_CHAT_MESSAGE(): string {
    return 'new_chat_message';
  }
}

class RemoteMethods {
  public static get LEAVE_ROOM(): string {
    return 'LeaveRoom';
  }

  public static get JOIN_ROOM(): string {
    return 'JoinRoom';
  }
}
