<div
  class="container"
  (@flashState.done)="flashState = 'default'"
  [@flashState]="flashState"
>
  <div class="icon">
    <mat-icon>photo_camera</mat-icon>
  </div>
  <div class="text">in</div>
  <div class="value">
    {{ countDown$ | async }}
  </div>
</div>
