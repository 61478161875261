import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LocalTrackCtrlService } from '../../services/local-track-ctrl.service';

@Component({
  selector: 'app-camera',
  styleUrls: ['./camera.component.scss'],
  templateUrl: './camera.component.html',
})
export class CameraComponent implements AfterViewInit, OnDestroy {
  @ViewChild('preview') previewElement: ElementRef;

  @Input()
  size: string;

  isInitializing = true;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private readonly trackCtrl: LocalTrackCtrlService,
    private readonly renderer: Renderer2
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async ngAfterViewInit() {
    if (this.previewElement && this.previewElement.nativeElement) {
      await this.initializeDevice();
      this.trackCtrl.videoTrackUpdated$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(async (x) => {
          await this.initializeDevice();
        });
    }
  }

  async initializePreview() {
    await this.initializeDevice();
  }

  private async initializeDevice() {
    try {
      this.isInitializing = true;
      await this.trackCtrl.getVideoTrack();
      const videoElement = this.trackCtrl.attachVideoTrack();
      videoElement.style.transform = 'scale(-1,1)';
      videoElement.style.borderRadius = '50%';
      videoElement.style.objectFit = 'cover';
      this.renderer.setStyle(videoElement, 'height', this.size ?? '10rem');
      this.renderer.setStyle(videoElement, 'width', this.size ?? '10rem');
      this.renderer.appendChild(
        this.previewElement.nativeElement,
        videoElement
      );
    } catch (error) {
      if (error instanceof DOMException && error.name === 'NotAllowedError') {
        //Permission for Video / Audio is not granted
      }
    } finally {
      this.isInitializing = false;
    }
  }
}
