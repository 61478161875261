import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FlashState, ZoomState } from './call-ctrl.service';

@Injectable({
  providedIn: 'root',
})
export class CapabilitiesService {
  private _availableCapabilities = new BehaviorSubject<Capabilities>({
    flash: FlashState.NotSupported,
    zoom: ZoomState.Default,
    cameraWidth: null,
    cameraHeight: null,
  });
  public capabilitiesChanged$ = this._availableCapabilities.asObservable();

  constructor() {}

  public updateCapabilities(capabilities: Capabilities) {
    this._availableCapabilities.next(capabilities);
  }
}

export interface Capabilities {
  flash: FlashState;
  zoom: ZoomState;
  cameraWidth: number;
  cameraHeight: number;
}
