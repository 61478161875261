import { UpdateNotesDocumentationBody } from '../../../../api/gen';

export class UpdateNotesDocumentationRequest {
  note?: string | null;

  static toBody(
    request: UpdateNotesDocumentationRequest
  ): UpdateNotesDocumentationBody {
    return { note: request.note } as UpdateNotesDocumentationBody;
  }
}
