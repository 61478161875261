<div class="container">
  <div class="header">
    <!-- TODO: Show image name as soon as we can change it in client -->
    <!--<span>{{currentImage.name}}.{{currentImage.extension}}</span> -->
    <span></span>

    <button
      *ngIf="allowedControls.edit"
      (click)="onStartAnnotating()"
      [class.selected]="(mode$ | async) === Mode.Annotate_Attachment"
      class="action-button"
      mat-button
      i18n
    >
      Bearbeiten
    </button>

    <button
      *ngIf="allowedControls.present"
      (click)="onPresentAttachment()"
      [class.selected]="!!(isAttachmentPresented | async)"
      class="action-button"
      mat-button
      i18n
    >
      Präsentieren
    </button>

    <button
      *ngIf="allowedControls.ocr"
      (click)="onReadTextWithOcr()"
      [disabled]="readTextWithOcrDisabled | async"
      class="action-button"
      mat-button
      i18n
    >
      Texte auslesen
    </button>

    <div>
      <mat-icon (click)="close()">close</mat-icon>
    </div>
  </div>
  <div class="content">
    <div class="wrapper">
      <app-drawing-canvas
        #drawingCanvas
        (drawn)="drawOnVideo($event)"
        [canvasSyncService]="canvasSyncService"
        [externalDrawEvent$]="videoChatService.userDrawEvent$"
        [mode$]="callCtrlService.modeChannel$.asObservable()"
        [ngClass]="{
          'ignore-pointer':
            (callCtrlService.modeChannel$ | async) !== Mode.Point
        }"
      >
      </app-drawing-canvas>
      <app-annotate-image
        #annotateImage
        (finishedAnnotating)="finishAnnotating($event)"
        [srcImage]="currentImage"
      >
      </app-annotate-image>
    </div>
  </div>
</div>
