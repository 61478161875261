import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { combineLatest, first, Observable, Subject } from 'rxjs';
import { CallCtrlService, ZoomState } from '../../services/call-ctrl.service';
import {
  PermissionService,
  PermissionUpdate,
} from '../../services/permission.service';
import { ContentType } from '../../services/videochat-content.service';
import {
  ConnectionState,
  DiagnosticsService,
} from '../../services/diagnostics/diagnostics.service';
import { MatDialog } from '@angular/material/dialog';
import { map, takeUntil } from 'rxjs/operators';
import {
  CamState,
  DeviceService,
  MicState,
} from '../../services/device.service';
import { Mode } from '../../../annotations/drawing-canvas/drawing-canvas.component';
import { AppointmentAttachmentsService } from '../../services/appointment-attachments.service';
import { Platform, getPlatform } from '../../../../detectPlatform';
import { UnreadMessagesService } from '@mad-tech/aidar-live-chat';
import { AttachmentPanelComponent } from '../attachment-panel/attachment-panel.component';

@Component({
  selector: 'app-portrait-call-ctrl',
  templateUrl: './portrait-call-ctrl.component.html',
  styleUrls: ['./portrait-call-ctrl.component.scss'],
})
export class PortraitCallCtrlComponent {
  @Input() public pictureLoading = false;
  @Output() callEnded = new EventEmitter<void>();
  @Output() takeScreenshot = new EventEmitter<void>();

  @ViewChild('attachmentPanel')
  attachmentPanelComponent: AttachmentPanelComponent;

  public Mode = Mode;
  public isCameraEnabled$: Observable<boolean>;
  public isMicrophoneEnabled$: Observable<boolean>;
  public permissionsGranted$: Observable<PermissionUpdate>;
  protected attachmentCount$ = new Observable<number>();

  protected unreadMessagesCount$ = new Observable<number>();

  public ZoomState = ZoomState;

  public expaned = false;

  private unsubscribe$ = new Subject<void>();

  protected videoCtrlsEnabled = false;

  constructor(
    public readonly dialog: MatDialog,
    protected readonly callCtrlService: CallCtrlService,
    private readonly permissionService: PermissionService,
    private readonly unreadMessagesService: UnreadMessagesService,
    diagnostics: DiagnosticsService,
    attachmentService: AppointmentAttachmentsService,
    deviceService: DeviceService
  ) {
    if (getPlatform() === Platform.iOS || getPlatform() === Platform.Android)
      deviceService.switchToFrontCam();

    this.isCameraEnabled$ = callCtrlService.subscribeToCamState().pipe(
      map((x) => x === CamState.On),
      takeUntil(this.unsubscribe$)
    );
    this.isMicrophoneEnabled$ = callCtrlService.subscribeToMicState().pipe(
      map((x) => x === MicState.Unmuted),
      takeUntil(this.unsubscribe$)
    );

    this.permissionsGranted$ = this.permissionService
      .observeChanges()
      .pipe(takeUntil(this.unsubscribe$));

    this.attachmentCount$ = attachmentService.attachments$.pipe(
      takeUntil(this.unsubscribe$),
      map((x) => x?.length ?? 0)
    );

    const attachment = attachmentService.onCurrentAttachment();

    attachment
      .pipe(
        takeUntil(this.unsubscribe$),
        map((x) => !!x)
      )
      .subscribe((attachmentShown) => {
        if (attachmentShown && this.expaned) this.expaned = false;
      });

    combineLatest([attachment, diagnostics.diagnosticsChanged$])
      .pipe(
        takeUntil(this.unsubscribe$),
        map((x) => {
          const currentAttachment = x[0];
          const diagnostics = x[1];

          return (
            currentAttachment == null &&
            diagnostics.remoteParticipantConnected &&
            !diagnostics.remoteConnectionLost &&
            diagnostics.remoteCameraTurnedOn &&
            diagnostics.connectionState == ConnectionState.Connected
          );
        })
      )
      .subscribe((x) => {
        this.videoCtrlsEnabled = x;
      });

    this.unreadMessagesCount$ = this.unreadMessagesService
      .onUnreadMessagesChanged()
      .pipe(takeUntil(this.unsubscribe$));
    this.unreadMessagesService.startCounting();
  }

  protected async toggleMic(): Promise<void> {
    await this.callCtrlService.toggleMicState();
  }

  protected async toggleVideo(): Promise<void> {
    await this.callCtrlService.toggleCamState();
  }

  endCall(): void {
    this.callEnded.emit();
  }

  takePicture(): void {
    this.takeScreenshot.emit();
  }

  toggleExpanded(): void {
    this.expaned = !this.expaned;
    if (!this.expaned) {
      // Start counting again, even if live chat tab is selected
      this.unreadMessagesService.startCounting();
    } else if (
      this.expaned &&
      this.attachmentPanelComponent.liveChatTabSelected
    ) {
      // Stop counting if live chat tab is selected when expanding again
      this.unreadMessagesService.stopCounting();
    }
  }

  public expand(value: boolean): void {
    this.expaned = value;
  }

  changeZoom(): void {
    this.callCtrlService
      .subscribeToZoomState()
      .pipe(first())
      .subscribe((x) => {
        if (x === ZoomState.Zoom1) {
          this.callCtrlService.changeZoom(ZoomState.Default);
        } else {
          this.callCtrlService.changeZoom(ZoomState.Zoom1);
        }
      });
  }

  protected readonly ContentType = ContentType;
}
