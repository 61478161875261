import { Component, Input, OnInit } from '@angular/core';
import { sameDay } from '../../util/isSameDay';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
})
export class DateRangeComponent implements OnInit {
  @Input() startDate: Date | string;
  @Input() endDate: Date | string | null;

  constructor() {}

  ngOnInit(): void {}

  endedOnStartedDay(): boolean {
    if (!this.endDate) return false;
    return sameDay(this.startDate, this.endDate);
  }
}
