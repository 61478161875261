import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { saveAs } from 'file-saver-es';
import { map, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  AssociatedCallPeriod,
  AttachmentDto,
  AttachmentService,
} from '../../../../api/gen';
import { CallAttachmentType } from '../../../shared/models/attachment/attachment.models';
import {
  ViewAttachmentData,
  ViewAttachmentDialogComponent,
} from '../../dialogs/view-attachment-dialog/view-attachment-dialog.component';
import { Attachment } from '../attachments/data/attachment';
import { CallNotesComponent } from '../../../shared/call-notes/call-notes.component';
import {
  EditAttachmentDialogData,
  EditAttachmentNoteDialogComponent,
} from '../../../shared/dialogs/edit-attachment-note-dialog/edit-attachment-note-dialog.component';
import {
  DeleteAttachmentDialogComponent,
  DeleteAttachmentDialogData,
} from '../../../shared/dialogs/delete-attachment-dialog/delete-attachment-dialog.component';

@Component({
  selector: 'app-inquiry-details-call-data',
  templateUrl: './inquiry-details-call-data.component.html',
  styleUrls: [
    './inquiry-details-call-data.component.scss',
    '../inquiry-details-tabs.scss',
  ],
})
export class InquiryDetailsCallDataComponent implements OnInit, OnDestroy {
  @Input() refresh: Subject<void>;
  @Input() inquiryId: string;

  @ViewChild('notes') notes: CallNotesComponent;

  duringAppointmentAttachments: Attachment[] = [];

  protected loadingCount$ = new Subject<number>();
  protected editNotes = false;
  private unsubscribe$ = new Subject<void>();

  CallAttachmentType = CallAttachmentType;

  protected textFinished: string = $localize`Fertig`;
  protected textEdit: string = $localize`Bearbeiten`;

  constructor(
    private readonly attachmentService: AttachmentService,
    private readonly dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.refreshAttachments();
    this.refresh.pipe(takeUntil(this.unsubscribe$)).subscribe((_) => {
      this.notes.refresh();
      this.refreshAttachments();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected toggleFormCtrl() {
    this.editNotes = !this.editNotes;
  }

  protected async openAttachment(data: Attachment) {
    let viewAttachmentData = {
      currentAttachment: data,
      allAttachments: this.duringAppointmentAttachments,
    } as ViewAttachmentData;
    this.dialog.open(ViewAttachmentDialogComponent, {
      data: viewAttachmentData,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'fullscreen-dialog',
    });
  }

  deleteAttachment(data: Attachment) {
    const dialogRef = this.dialog.open(DeleteAttachmentDialogComponent, {
      data: {
        attachment: data,
      } as DeleteAttachmentDialogData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refreshAttachments();
      }
    });
  }

  protected downloadFile(file: AttachmentDto) {
    const fileName = `${file.fileName}-edited.${file.extension}`;
    saveAs(file.annotationBlobUrl, fileName);
  }

  protected downloadFileOriginal(file: AttachmentDto) {
    const fileName = `${file.fileName}.${file.extension}`;
    saveAs(file.blobUrl, fileName);
  }

  protected editAttachmentNotes(data: AttachmentDto) {
    const dialogRef = this.dialog.open(EditAttachmentNoteDialogComponent, {
      data: {
        attachmentIdentifier: data.attachmentIdentifier,
        attachmentName: data.fileName,
      } as EditAttachmentDialogData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refreshAttachments();
      }
    });
  }

  private refreshAttachments() {
    this.attachmentService
      .attachmentInquiryInquiryIdPost(this.inquiryId, {})
      .pipe(
        map((attachments) => {
          return attachments.map((attachmentDto) =>
            Attachment.fromRemoteAttachment(attachmentDto)
          );
        })
      )
      .subscribe({
        next: (attachments) => {
          this.duringAppointmentAttachments = attachments.filter(
            (a) => a.period === AssociatedCallPeriod.During
          );
          this.cdr.markForCheck();
        },
      });
  }
}
