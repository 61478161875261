import { Department, EmployeeDto, EmployeeType } from '../../../api/gen';

export class Employee {
  externalIdentifier: string;
  firstName: string;
  lastName: string;
  email: string;
  emailVerified: boolean;
  phoneNumber?: string | null;
  enabled?: boolean;
  username: string;
  avatarPath?: string;
  avatarThumbnailPath?: string;
  type: EmployeeType;
  canReadDataOfOthers?: boolean;
  isDemoUser?: boolean;
  department?: Department;
  tenantId?: string | null;

  static fromDto(dto: EmployeeDto): Employee {
    return {
      externalIdentifier: dto.externalIdentifier,
      firstName: dto.firstName,
      lastName: dto.lastName,
      email: dto.eMail,
      emailVerified: dto.eMailVerified,
      phoneNumber: dto.phoneNumber,
      enabled: dto.enabled,
      username: dto.username,
      avatarPath: dto.avatarPath,
      avatarThumbnailPath: dto.avatarThumbnailPath,
      type: dto.type,
      canReadDataOfOthers: dto.canReadDataOfOthers,
      isDemoUser: dto.isDemoUser,
      department: dto.department,
      tenantId: dto.tenantId,
    } as Employee;
  }
}
