import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditInquiryBaseDataRequest } from '../../../model/inquiry/request/edit-inquiry-base-data';
import { InquiryEditService } from '../../../services/api/inquiry-edit.service';

@Component({
  selector: 'app-edit-basic-inquiry-info-dialog',
  templateUrl: './edit-basic-inquiry-info-dialog.component.html',
  styleUrls: ['./edit-basic-inquiry-info-dialog.component.scss'],
  providers: [InquiryEditService],
})
export class EditBasicInquiryInfoDialogComponent {
  editInfoFg: FormGroup = new FormGroup({
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    phoneNumber: new FormControl({ value: '', disabled: true }),
    email: new FormControl('', [Validators.email]),
  });

  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<EditBasicInquiryInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditInquiryBaseDataFields,
    private updateService: InquiryEditService
  ) {
    this.editInfoFg.setValue({
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      email: data.email,
    });
  }

  get email() {
    return this.editInfoFg.get('email');
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  saveData() {
    const currentFg = this.editInfoFg.value;
    this.isLoading = true;
    this.editInfoFg.disable();
    const requestData = {
      inquiryId: this.data.inquiryId,
      newEmail: currentFg.email,
      newFirstName: currentFg.firstName,
      newLastName: currentFg.lastName,
    } as EditInquiryBaseDataRequest;
    this.updateService
      .editInquiryBaseData(this.data.inquiryId, requestData)
      .subscribe(() => {
        this.isLoading = false;
        this.editInfoFg.enable();
        this.dialogRef.close(true);
      });
  }
}

export interface EditInquiryBaseDataFields {
  inquiryId: string;
  firstName?: string;
  lastName?: string;
  phoneNumber: string;
  email?: string;
}
