import {
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Output,
} from '@angular/core';

@Directive({
  selector: '[appDropzone]',
})
export class DropzoneDirective {
  @Output() onFileDropped = new EventEmitter<FileList>();

  @HostBinding('style.background-color') private backgroundColor =
    'transparent';
  @HostBinding('style.border') private border = 'none';

  constructor(private el: ElementRef) {}

  @HostListener('dragover', ['$event'])
  public onDragOver(evt: any): any {
    evt.preventDefault();
    evt.stopPropagation();
    this.border = 'dotted 2px #FF4D2A';
    this.backgroundColor = '#eeeeee';
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt: any): any {
    evt.preventDefault();
    evt.stopPropagation();
    this.border = 'none';
    this.backgroundColor = 'transparent';
  }

  @HostListener('drop', ['$event'])
  public ondrop(evt: any): any {
    evt.preventDefault();
    evt.stopPropagation();
    this.border = 'none';
    this.backgroundColor = 'transparent';
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.onFileDropped.emit(files);
    }
  }
}
