import { Component, Input, OnInit } from '@angular/core';
import { Inquiry } from '../../model/inquiry/inquiry';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Clipboard } from '@angular/cdk/clipboard';
import { BehaviorSubject, filter } from 'rxjs';

@Component({
  selector: 'app-copy-inquiry-link',
  templateUrl: './copy-inquiry-link.component.html',
  styleUrls: ['./copy-inquiry-link.component.scss'],
})
export class CopyInquiryLinkComponent implements OnInit {
  @Input() inquiry$: BehaviorSubject<Inquiry>;
  protected shareableLink: BehaviorSubject<string>;

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly clipboard: Clipboard
  ) {
    this.shareableLink = new BehaviorSubject('Lade Link...');
  }

  ngOnInit() {
    this.inquiry$.pipe(filter((x) => !!x)).subscribe((i) => {
      this.shareableLink.next(i.invitation.shareableLink);
    });
  }

  protected copyToClipboard() {
    this.clipboard.copy(this.shareableLink.value);
    this.snackBar.open($localize`📋 Link kopiert`, null, {
      duration: 2500,
    });
  }
}
