import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-redial-dialog',
  templateUrl: './redial-dialog.component.html',
  styleUrls: ['./redial-dialog.component.scss'],
})
export class RedialDialogComponent {
  constructor(
    private readonly dialogRef: MatDialogRef<RedialDialogComponent>
  ) {}
  isMobile() {
    return window.matchMedia('only screen and (max-width: 760px)').matches;
  }

  closeDialog() {
    this.dialogRef.close(true);
  }
}
