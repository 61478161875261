<mat-toolbar color="primary">
  <div class="content">
    <button
      mat-icon-button
      class="menu-bar-hamburger-menu"
      aria-label="Navigation hamburger menu"
      i18n-aria-label
      [matMenuTriggerFor]="navigationMenu"
    >
      <mat-icon>menu</mat-icon>
    </button>

    <mat-menu #navigationMenu="matMenu">
      <button mat-menu-item routerLink="/scheduling">
        <mat-icon>schedule</mat-icon>
        <span i18n>Termine</span>
      </button>
      <button mat-menu-item routerLink="/company">
        <mat-icon>apartment</mat-icon>
        <span i18n>Firma</span>
      </button>
      <mat-divider></mat-divider>
      <button
        *ngIf="roleService.isInRole(BackendRole.Manager) | async"
        mat-menu-item
        routerLink="/employees"
      >
        <mat-icon>people</mat-icon>
        <span i18n>Mitarbeiter</span>
      </button>
      <button mat-menu-item routerLink="/profile">
        <mat-icon>account_circle</mat-icon>
        <span i18n>Profil</span>
      </button>
    </mat-menu>

    <div *ngIf="companySettings">
      <span class="menu-title">{{ companySettings.displayName }} </span>
      <span i18n>Fernhilfe</span>
    </div>
    <div *ngIf="!companySettings">
      <span class="menu-title" i18n>blankmile. Fernhilfe</span>
    </div>

    <span class="menu-spacer"></span>

    <button
      (click)="joinRunningAppointment()"
      *ngIf="openInquiries.length > 0"
      aria-label="Join running appointment"
      i18n-aria-label
      class="join-appointment-button"
      mat-button
    >
      <mat-icon class="join-appointment-button-icon"
        >radio_button_checked
      </mat-icon>
      <span class="join-appointment-button-title" i18n
        >Laufenden Termin beitreten</span
      >
    </button>

    <span class="menu-spacer"></span>

    <app-quota-display></app-quota-display>
    <app-notification-center></app-notification-center>

    <div [matMenuTriggerFor]="menu" class="profile">
      <div
        *ngIf="profileDataService.employeeInformation$ | async as employee"
        class="profile-info-content"
      >
        <span class="profile-info-display-name"
          >{{
            canBuildDisplayName(employee)
              ? buildAgentDisplayName(employee)
              : employee.email ?? "User"
          }}
        </span>

        <img
          class="clip-circle"
          src="{{
            employee.avatarPath ?? 'assets/images/default_profile_icon.svg'
          }}"
          alt="Profilbild"
          i18n-alt
        />
      </div>
    </div>
  </div>

  <mat-menu #menu="matMenu">
    <button mat-menu-item routerLink="/profile">
      <mat-icon>settings</mat-icon>
      <span class="ns" i18n>Einstellungen</span>
    </button>
    <button
      mat-menu-item
      onclick="window.open('https://blankmile.com/kontakt/', '_blank');"
      type="button"
    >
      <mat-icon>help_outline</mat-icon>
      <span i18n>Hilfe</span>
    </button>
    <mat-divider></mat-divider>
    <button (click)="onLogout()" mat-menu-item>
      <mat-icon>logout</mat-icon>
      <span i18n>Ausloggen</span>
    </button>
  </mat-menu>
</mat-toolbar>
