<div class="container">
  <app-landscape-appointment
    *ngIf="inquiryId && !usePortrait"
    [inquiryId]="inquiryId"
    [invitationAlreadySent]="invitationAlreadySent"
  ></app-landscape-appointment>
  <app-portrait-appointment
    class="element-to-rotate"
    *ngIf="inquiryId && usePortrait"
    [invitationAlreadySent]="invitationAlreadySent"
  ></app-portrait-appointment>
</div>
