<div #openCloseButton>
  <button mat-icon-button (click)="openCloseNotificationCenter()">
    <mat-icon
      [matBadge]="(notifications$ | async).length"
      [matBadgeHidden]="(notifications$ | async).length === 0"
      matBadgeColor="accent"
      matBadgeOverlap="true"
      matBadgeSize="small"
      >{{
        (isNotificationCenterOpen$ | async)
          ? "notifications_none"
          : "notifications"
      }}</mat-icon
    >
  </button>
</div>

<div
  #container
  class="notification-center-container"
  *ngIf="isNotificationCenterOpen$ | async"
>
  <mat-card appearance="raised">
    <mat-card-header>
      <mat-card-title class="notification-center-title"
        >Benachrichtigungen
      </mat-card-title>
      <mat-card-subtitle class="notification-center-subtitle"
        >Alle Updates der letzten 30 Tage
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="notification-center-content">
        @if (isLoading$ | async) {
        <div class="notification-center-content-loading">
          <mat-spinner diameter="20"></mat-spinner>
        </div>
        } @else {
        <ng-container *ngTemplateOutlet="notifications"></ng-container>
        }
      </div>
    </mat-card-content>
  </mat-card>
</div>

<ng-template #notifications>
  <div
    *ngIf="notifications$ | async; let notifications"
    class="notification-center-content-notifications"
  >
    @for (notification of notifications; track notification.id) {
    <app-notification-item
      [notification]="notification"
      (onNotificationRead)="onNotificationRead($event)"
    ></app-notification-item>

    } @empty {
    <div class="notification-center-content-read">
      <mat-icon color="primary" class="content-read-icon"
        >check_circle_outline
      </mat-icon>
      <span class="content-read-text">
        Sie haben bereits <b>alle</b> Nachrichten der letzten 30 Tage gelesen.
      </span>
    </div>
    }
  </div>
</ng-template>
