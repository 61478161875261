<div class="container">
  <div
    #attachmentContainer
    class="attachment-container"
    *ngIf="!isLoading; else loading"
  >
    <div
      *ngFor="let x of attachments; trackBy: trackImageBy"
      class="attachment"
    >
      <ng-container
        [ngTemplateOutletContext]="{ data: x }"
        [ngTemplateOutlet]="attachment"
      ></ng-container>
    </div>
    <div *ngIf="attachments?.length === 0" class="placeholder" i18n>
      Noch keine Fotos
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="loading-spinner">
    <mat-spinner [diameter]="25"></mat-spinner>
  </div>
</ng-template>

<ng-template #attachment let-data="data">
  <app-attachment-list-card
    [title]="data.name"
    [extension]="data.extension"
    [createdAt]="data.createdAt"
    [previewUrl]="data.annotated_thumbnail_path || data.thumbnail_path"
    (onclick)="
      data.type === CallAttachmentType.Image ? openAttachment(data.path) : null
    "
    [menu]="menu"
  >
  </app-attachment-list-card>
  <mat-menu #menu="matMenu">
    <button (click)="deleteAttachment(data.id)" mat-menu-item>
      <mat-icon>delete</mat-icon>
      <span i18n>Löschen</span>
    </button>
  </mat-menu>
</ng-template>
