import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { first, map, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AssociatedCallPeriod, AttachmentService } from '../../../../api/gen';
import { AttachmentFileService } from '../../../shared/services/attachment-file.service';
import {
  Attachment,
  AttachmentWithState,
} from '../attachments/data/attachment';
import { CallAttachmentType } from '../../../shared/models/attachment/attachment.models';
import {
  EditAttachmentDialogData,
  EditAttachmentNoteDialogComponent,
} from '../../../shared/dialogs/edit-attachment-note-dialog/edit-attachment-note-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { saveAs } from 'file-saver-es';
import {
  DeleteAttachmentDialogComponent,
  DeleteAttachmentDialogData,
} from '../../../shared/dialogs/delete-attachment-dialog/delete-attachment-dialog.component';

@Component({
  selector: 'app-inquiry-details-attachments',
  templateUrl: './inquiry-details-attachments.component.html',
  styleUrls: [
    './inquiry-details-attachments.component.scss',
    '../inquiry-details-tabs.scss',
  ],
})
export class InquiryDetailsAttachmentsComponent implements OnInit, OnDestroy {
  @Input() refresh: Subject<void>;
  @Input() inquiryId: string;
  localAfterAppointmentAttachments: AttachmentWithState[] = [];
  remoteAfterAppointmentAttachments: Attachment[] = [];
  attachmentsToShow: Attachment[] = [];
  private unsubscribe$ = new Subject<void>();

  constructor(
    private readonly dialog: MatDialog,
    private readonly attachmentService: AttachmentService,
    private readonly attachmentFileService: AttachmentFileService,
    private readonly ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.refreshAttachments();
    this.refresh.pipe(takeUntil(this.unsubscribe$)).subscribe((_) => {
      this.refreshAttachments();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  pushOrUpdateLocalAttachment(attachmentWithState: AttachmentWithState) {
    const index = this.localAfterAppointmentAttachments.findIndex(
      (a) => a.name === attachmentWithState.name
    );
    if (index < 0) {
      this.localAfterAppointmentAttachments.push(attachmentWithState);
    } else {
      this.localAfterAppointmentAttachments[index] = attachmentWithState;
    }
  }

  updateAttachmentsView() {
    this.mergeLocalAndRemoteAttachments();
    this.ref.markForCheck();
  }

  mergeLocalAndRemoteAttachments() {
    const localAttachments = this.localAfterAppointmentAttachments.flatMap(
      (localAttachment) => Attachment.fromLocalAttachment(localAttachment)
    );

    this.attachmentsToShow = [];
    this.attachmentsToShow =
      this.remoteAfterAppointmentAttachments.concat(localAttachments);
  }

  onFileSelected(event: Event) {
    if (event.target) {
      const eventTarget = event.target as HTMLInputElement;

      if (eventTarget.files) {
        const files = this.attachmentFileService.fileListToArray(
          eventTarget.files
        );
        this.attachmentFileService
          .uploadAttachments(AssociatedCallPeriod.After, this.inquiryId, files)
          .subscribe({
            next: (attachmentWithState) => {
              this.pushOrUpdateLocalAttachment(attachmentWithState);
              this.updateAttachmentsView();
            },
          });
      }
    }
  }

  private refreshAttachments() {
    this.attachmentService
      .attachmentInquiryInquiryIdPost(this.inquiryId, {})
      .pipe(
        first(),
        map((attachments) => {
          return attachments.map((attachmentDto) =>
            Attachment.fromRemoteAttachment(attachmentDto)
          );
        })
      )
      .subscribe({
        next: (attachments) => {
          this.remoteAfterAppointmentAttachments = attachments.filter(
            (a) => a.period === AssociatedCallPeriod.After
          );
        },
        complete: () => {
          this.updateAttachmentsView();
        },
      });
  }

  protected readonly CallAttachmentType = CallAttachmentType;

  editAttachmentNotes(data: Attachment) {
    const dialogRef = this.dialog.open(EditAttachmentNoteDialogComponent, {
      data: {
        attachmentIdentifier: data.attachmentIdentifier,
        attachmentName: data.fileName,
      } as EditAttachmentDialogData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refreshAttachments();
      }
    });
  }

  downloadAttachment(data: Attachment) {
    const fileName = `${data.fileName}.${data.extension}`;
    saveAs(data.blobUrl, fileName);
  }

  deleteAttachment(data: Attachment) {
    const dialogRef = this.dialog.open(DeleteAttachmentDialogComponent, {
      data: {
        attachment: data,
      } as DeleteAttachmentDialogData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refreshAttachments();
      }
    });
  }
}
