import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// TODO: This could also be done via route interception
@Injectable({
  providedIn: 'root',
})
export class OverscrollService {
  private overscrollChanged = new Subject<OverScrollBehavior>();
  private currentBehavior: OverScrollBehavior = OverScrollBehavior.Default;

  public turnOffOverScroll() {
    this.currentBehavior = OverScrollBehavior.NoScroll;
    this.overscrollChanged.next(this.currentBehavior);
    document.documentElement.style.overscrollBehavior = 'none';
    document.body.style.overscrollBehavior = 'none';
  }

  public resetToDefault() {
    this.currentBehavior = OverScrollBehavior.Default;
    this.overscrollChanged.next(this.currentBehavior);
    document.documentElement.style.overscrollBehavior = 'auto';
    document.body.style.overscrollBehavior = 'auto';
  }
}

export enum OverScrollBehavior {
  Default,
  NoScroll,
}
