import { Injectable } from '@angular/core';

// This service is needed as canvas size != canvas element size
// Since we use the canvas also during presentation etc, we need some way to keep the canvas width & height in
// sync with the dimensions of the underlying element.
// The alternative would be do do this directly in the drawing canvas component with regards of the element dimensions
// Actually, this should be the preferred way at one point. But for now, it also syncs the canvas style dimensions when
// being in presentation mode. Until we also sync the aspect ratio of presented things from helper => customer
// we need to keep it, as otherwise drawing on presented things is broken.
// As of today, we only sync video aspect ratio / dimensions from customer => helper
@Injectable()
export class CanvasSyncService {
  private drawingCanvas: HTMLCanvasElement | undefined;
  private referenceElement: HTMLElement | undefined;

  private observer: ResizeObserver | undefined;

  constructor() {
    this.observer = new ResizeObserver((entries) => {
      // see https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
      // window.requestAnimationFrame(() => {
      if (this.drawingCanvas) {
        this.updateDrawingCanvas(
          entries[0].contentRect.width,
          entries[0].contentRect.height
        );
      }
      //});
    });
  }

  setDrawingCanvas(drawingCanvas: HTMLCanvasElement): void {
    this.drawingCanvas = drawingCanvas;
    if (this.referenceElement) {
      this.updateDrawingCanvas(
        this.referenceElement.clientWidth,
        this.referenceElement.clientHeight
      );
    }
  }

  referenceElementChanged(newElement: HTMLElement | null) {
    if ((!newElement && this.referenceElement) || this.referenceElement) {
      this.observer.unobserve(this.referenceElement);
    }
    if (newElement) {
      this.observer.observe(newElement);
    }
    this.referenceElement = newElement;

    if (this.drawingCanvas) {
      this.updateDrawingCanvas(newElement.clientWidth, newElement.clientHeight);
    }
  }

  private updateDrawingCanvas(width: number, height: number) {
    // This line is needed to have the right height & width when being in the presentation mode
    // TODO: We should also sync the aspect ratio when presenting stuff
    this.drawingCanvas.style.height = height + 'px';
    this.drawingCanvas.style.width = width + 'px';
    this.drawingCanvas.width = width;
    this.drawingCanvas.height = height;
  }
}
