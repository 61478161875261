<div class="inquiry-link-container">
  <h4 class="inquiry-link-heading" i18n>Einladungslink</h4>
  <app-copy-inquiry-link [inquiry$]="inquiry$"></app-copy-inquiry-link>
</div>

<div class="inquiry-info-container">
  <h4 class="inquiry-link-heading" i18n>Details zum Termin</h4>
  <div class="detail-data" *ngIf="!isLoading; else loading">
    <div class="headers">
      <div class="header" i18n>Name:</div>
      <div class="header" i18n>E-Mail:</div>
      <div class="header" i18n>Telefonnummer:</div>
      <div class="header" i18n>Termin erstellt am:</div>
    </div>
    <div class="values">
      <div class="value">
        <div *ngIf="inquiry?.firstName || inquiry?.lastName">
          {{ inquiry.firstName }} {{ inquiry.lastName }}
        </div>
        <div
          *ngIf="!(inquiry?.firstName || inquiry?.lastName)"
          class="no-value-set"
          i18n
        >
          Unbekannt
        </div>
      </div>
      <div class="value">
        <div *ngIf="inquiry?.email">
          {{ inquiry.email }}
        </div>
        <div *ngIf="!inquiry?.email" class="no-value-set" i18n>Unbekannt</div>
      </div>
      <div class="value">
        <div *ngIf="inquiry?.phoneNumber">
          {{ inquiry.phoneNumber }}
        </div>
        <div *ngIf="!inquiry?.phoneNumber" class="no-value-set" i18n>
          Unbekannt
        </div>
      </div>
      <div class="value">
        <div *ngIf="inquiry?.createdAt">
          {{ inquiry.createdAt | date : "EEEEEE, dd.MM.yyyy HH:mm" }}
        </div>
        <div *ngIf="!inquiry?.createdAt" class="no-value-set" i18n>
          Unbekannt
        </div>
      </div>
    </div>
  </div>

  <div>
    <button
      [disabled]="isLoading"
      (click)="editContactDetails()"
      color="primary"
      mat-flat-button
      class="inquiry-info-edit-button"
    >
      <mat-icon>edit</mat-icon>
      <span i18n>Bearbeiten</span>
    </button>
  </div>
</div>

<ng-template #loading>
  <div class="loading-spinner">
    <mat-spinner [diameter]="25"></mat-spinner>
  </div>
</ng-template>
