import { AssociatedCallPeriod, AttachmentDto, Origin } from '../../../api/gen';

export class Attachment {
  containerName?: string | null;
  blobPath?: string | null;
  inquiryIdentifier?: string;
  attachmentIdentifier?: string | null;
  fileName?: string | null;
  note?: string | null;
  extension?: string | null;
  contentType?: string | null;
  blobUrl?: string | null;
  thumbnailUrl?: string | null;
  annotationBlobUrl?: string | null;
  annotationThumbnailUrl?: string | null;
  createdAt?: string;
  period?: AssociatedCallPeriod;
  origin?: Origin;
  shared?: boolean;
  isInProcess?: boolean;
  processPercent?: number | null;

  static fromDto(dto: AttachmentDto): Attachment {
    return {
      containerName: dto.containerName,
      blobPath: dto.blobPath,
      inquiryIdentifier: dto.inquiryIdentifier,
      attachmentIdentifier: dto.attachmentIdentifier,
      fileName: dto.fileName,
      note: dto.note,
      extension: dto.extension,
      contentType: dto.contentType,
      blobUrl: dto.blobUrl,
      thumbnailUrl: dto.thumbnailUrl,
      annotationBlobUrl: dto.annotationBlobUrl,
      annotationThumbnailUrl: dto.annotationThumbnailUrl,
      createdAt: dto.createdAt,
      period: dto.period,
      origin: dto.origin,
      shared: dto.shared,
      isInProcess: dto.isInProcess,
      processPercent: dto.processPercent,
    } as Attachment;
  }
}
