import { CreateNewScheduledInquiry, Gender } from '../../../../api/gen';
import { Invitation } from '../invitation';

export class CreateNewScheduledInquiryRequest {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  street?: string | null;
  houseNumber?: string | null;
  zipCode?: string | null;
  city?: string | null;
  scheduledFor?: string | null;
  startInstant?: boolean;
  gender?: Gender;
  assignedAgent?: string;
  invitation?: Invitation;

  static toDto(
    request: CreateNewScheduledInquiryRequest
  ): CreateNewScheduledInquiry {
    return {
      firstName: request.firstName,
      lastName: request.lastName,
      email: request.email,
      phoneNumber: request.phoneNumber,
      street: request.street,
      houseNumber: request.houseNumber,
      zipCode: request.zipCode,
      city: request.city,
      scheduledFor: request.scheduledFor,
      startInstant: request.startInstant,
      gender: request.gender,
      assignedAgent: request.assignedAgent,
      invitation: request.invitation
        ? Invitation.fromDto(request.invitation)
        : null,
    } as CreateNewScheduledInquiry;
  }
}
