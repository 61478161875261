import { Observable, Subject } from 'rxjs';

export class AudioAnalyzer {
  audioContext: AudioContext;
  analyzer: AnalyserNode;
  audioSource: MediaStreamAudioSourceNode;

  private levelStream: Subject<number>;

  private intervalId: number;

  constructor(stream: MediaStream) {
    this.audioContext = new AudioContext();
    this.analyzer = this.audioContext.createAnalyser();
    this.analyzer.fftSize = 512;
    this.analyzer.smoothingTimeConstant = 0.1;

    this.initialize(stream);
  }

  private initialize(stream: MediaStream): Observable<number> {
    this.stop();
    // Taken from: https://www.webrtc-developers.com/how-to-know-if-my-microphone-works/
    // and https://github.com/twilio/video-quickstart-js/blob/master/examples/util/waveform.js#L83

    this.audioSource = this.audioContext.createMediaStreamSource(stream);
    this.audioSource.connect(this.analyzer);

    this.levelStream = new Subject<number>();

    this.intervalId = window.setInterval(() => {
      // Compute the max volume level (-Infinity...0)
      const fftBins = new Float32Array(this.analyzer.frequencyBinCount); // Number of values manipulated for each sample
      this.analyzer.getFloatFrequencyData(fftBins);
      const frequencyRangeData = new Uint8Array(
        this.analyzer.frequencyBinCount
      );
      this.analyzer.getByteFrequencyData(frequencyRangeData);
      const sum = frequencyRangeData.reduce((p, c) => p + c, 0);
      // audioMeter varies from 0 to 10
      const audioMeter = Math.sqrt(sum / frequencyRangeData.length);
      this.levelStream.next(Math.trunc(audioMeter));
    }, 500);

    return this.levelStream.asObservable();
  }

  public stop() {
    if (this.intervalId) window.clearInterval(this.intervalId);
    if (this.audioSource) {
      this.audioSource.disconnect(this.analyzer);
      this.audioSource = null;
      this.levelStream.complete();
    }
  }

  public getObservable(): Observable<number> | undefined {
    return this.levelStream?.asObservable();
  }
}
