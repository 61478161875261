import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenu } from '@angular/material/menu';
import { MatTableDataSource } from '@angular/material/table';
import { CallAttachmentType } from 'projects/helper-client/src/app/shared/models/attachment/attachment.models';
import {
  ViewAttachmentData,
  ViewAttachmentDialogComponent,
} from '../../../dialogs/view-attachment-dialog/view-attachment-dialog.component';
import { Attachment } from '../data/attachment';

@Component({
  selector: 'app-attachment-preview',
  templateUrl: './attachment-preview.component.html',
  styleUrls: ['./attachment-preview.component.scss'],
})
export class AttachmentPreviewComponent implements OnInit {
  @Input() attachmentList?: Attachment[];
  @Input() menu?: MatMenu;
  @Input() emptyText?: string;

  protected dataSource: MatTableDataSource<Attachment> = new MatTableDataSource(
    []
  );

  protected displayedColumns: string[] = ['fileIcon', 'fileName', 'download'];
  protected defaultEmptyText: string = $localize`Keine Dateien.`;

  constructor(
    private readonly dialog: MatDialog,
    private readonly renderer: Renderer2
  ) {}

  ngOnInit(): void {}

  onDownloadAttachment(blobUrl: string) {
    const link = this.renderer.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', blobUrl);
    link.click();
    link.remove();
  }

  onRowClicked(attachment: Attachment) {
    const viewAttachmentData = {
      currentAttachment: attachment,
      allAttachments: this.attachmentList,
    } as ViewAttachmentData;
    this.dialog.open(ViewAttachmentDialogComponent, {
      data: viewAttachmentData,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'fullscreen-dialog',
    });
  }
}
