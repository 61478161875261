import { Component, Inject } from '@angular/core';
import { AttachmentService } from '../../../services/api/attachment.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { Attachment } from '../../../model/attachment/attachment';

@Component({
  selector: 'app-delete-attachment-dialog',
  templateUrl: './delete-attachment-dialog.component.html',
  styleUrls: ['./delete-attachment-dialog.component.scss'],
  providers: [AttachmentService],
})
export class DeleteAttachmentDialogComponent {
  protected isLoading$ = new BehaviorSubject(false);
  protected attachmentSource: string;
  constructor(
    private readonly attachmentService: AttachmentService,
    private readonly dialogRef: MatDialogRef<DeleteAttachmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteAttachmentDialogData
  ) {
    this.attachmentSource = data.attachment.annotationThumbnailUrl
      ? data.attachment.annotationThumbnailUrl
      : data.attachment.thumbnailUrl
      ? data.attachment.thumbnailUrl
      : 'assets/images/description.svg';
  }

  onDeleteAttachment() {
    this.isLoading$.next(true);
    this.attachmentService
      .deleteAttachment(this.data.attachment.attachmentIdentifier)
      .subscribe({
        next: (res) => {
          this.dialogRef.close(true);
        },
        error: () => {
          this.isLoading$.next(false);
        },
      });
  }
}

export interface DeleteAttachmentDialogData {
  attachment: Attachment;
}
