<div
  class="container"
  [@enterAnimation]="slideInState"
  (@enterAnimation.start)="animationStart($event)"
  (@enterAnimation.done)="animationDone($event)"
  (click)="clicked()"
>
  <img *ngIf="imgSrc" class="image" [src]="imgSrc" />
  <div *ngIf="!imgSrc" class="placeholder">
    <img src="assets/images/description.svg" class="image" />
  </div>
</div>
