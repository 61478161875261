import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { InquirySummary } from '../../scheduling/models/inquiry';
import { DashboardInformationService } from '../../scheduling/services/dashboard-information.service';

@Injectable({
  providedIn: 'root',
})
export class InquirySignalingService {
  notifyInquiryDataChangedSubject$ = new Subject<InquirySummary>();
  notifyNewInquiryAvailableSubject$ = new Subject<InquirySummary>();
  notifyAppointmentChangedSubject$ = new Subject<InquirySummary>();
  private hubConnection: signalR.HubConnection;
  private hubConnectionInit: Promise<void>;

  constructor() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.apiPath + `/inquiry-hub`)
      .configureLogging(signalR.LogLevel.Debug)
      .build();
    this.hubConnectionInit = this.hubConnection.start();
    this.addInquiryUpdateListener();
  }

  public addInquiryUpdateListener(): void {
    //fired when a customer updated his or her inquiry summary

    this.hubConnection.on(LocalMethods.NOTIFY_INQUIRY_DATA_CHANGED, (data) => {
      this.notifyInquiryDataChangedSubject$.next(
        this.createInquirySummaryFromRemoteData(data)
      );
    });

    //fired when a new inquiry is available
    this.hubConnection.on(LocalMethods.NOTIFY_NEW_INQUIRY_AVAILABLE, (data) => {
      this.notifyNewInquiryAvailableSubject$.next(
        this.createInquirySummaryFromRemoteData(data)
      );
    });

    //fired when an appointment changed
    this.hubConnection.on(LocalMethods.NOTIFY_APPOINTMENT_CHANGED, (data) => {
      this.notifyAppointmentChangedSubject$.next(
        this.createInquirySummaryFromRemoteData(data)
      );
    });
  }

  private createInquirySummaryFromRemoteData(data: any) {
    return {
      inquiryIdentifier: data.inquiryIdentifier,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber,
      inquiryCreatedAt: data.inquiryCreatedAt,
      scheduledFor: data.appointmentScheduledFor,
      appointmentConfirmed: data.scheduledForConfirmed,
      appointmentDeclined: data.scheduledForDeclined,
      endedAt: data.appointmentEndedAt,
      assignedEmployeeDisplayName:
        DashboardInformationService.getEmployeeDisplayName(data.employee),
      state: data.state,
      duration: DashboardInformationService.calculateCallDuration(
        data.appointmentScheduledFor,
        data.appointmentEndedAt
      ),
      attachmentCount: data.attachments?.length,
    } as InquirySummary;
  }
}

class LocalMethods {
  public static get NOTIFY_INQUIRY_DATA_CHANGED(): string {
    return 'notifyInquiryDataChanged';
  }

  public static get NOTIFY_NEW_INQUIRY_AVAILABLE(): string {
    return 'notifyNewInquiryAvailable';
  }

  public static get NOTIFY_APPOINTMENT_CHANGED(): string {
    return 'notifyAppointmentChanged';
  }
}
