import { Component, Input } from '@angular/core';
import {
  NotificationBarService,
  NotificationType,
} from '../../services/notification-bar.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.scss'],
})
export class NotificationBarComponent {
  @Input() closeable = true;

  protected notificationColor$ = new BehaviorSubject('#f08080');

  constructor(
    protected readonly notificationBarService: NotificationBarService
  ) {
    this.notificationBarService.getNotification().subscribe((notification) => {
      this.notificationColor$.next(
        this.getNotificationColorFromNotificationType(notification.type)
      );
    });
  }

  private getNotificationColorFromNotificationType(
    notificationType: NotificationType
  ): string {
    switch (notificationType) {
      case NotificationType.Info:
        return '#3fad11';
      case NotificationType.Warn:
        return '#ff8800';
      default:
        return '#c03636';
    }
  }

  onClose() {
    this.notificationBarService.setNotificationBarVisibility(false);
  }
}
