import { ChatApiInterface, ChatMessage } from '@mad-tech/aidar-live-chat';
import { map, Observable } from 'rxjs';
import { LiveChatService, LiveChatTranslationService } from '../../api/gen';
import { LiveChatDtoCasts } from './message.model';
import { Injectable } from '@angular/core';

// see https://stackoverflow.com/questions/51859038/angular-library-module-inject-service-with-abstract-class
@Injectable({
  providedIn: 'root',
})
export class HelperChatService implements ChatApiInterface {
  constructor(
    private readonly chatTranslationService: LiveChatTranslationService,
    private readonly liveChatService: LiveChatService
  ) {}

  fetchMessagesForInquiry(inquiryId: string): Observable<ChatMessage[]> {
    return this.liveChatService
      .chatAgentInquiryIdGet(inquiryId)
      .pipe(map((data) => data.map((x) => LiveChatDtoCasts.castFromDto(x))));
  }

  sendMessage(inquiryId: string, newMessage: string): Observable<ChatMessage> {
    return this.liveChatService
      .chatAgentInquiryIdPost(inquiryId, {
        message: newMessage,
        inquiryId: inquiryId,
      })
      .pipe(map((x) => LiveChatDtoCasts.castFromDto(x)));
  }

  translateChatMessage(messageId: string, locale: string): Observable<string> {
    return this.chatTranslationService
      .chatTranslationsMessageIdPatch(messageId, {
        messageId: messageId,
        targetLocale: locale,
      })
      .pipe(map((x) => x.translation));
  }
}
