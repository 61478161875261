/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular-ivy';
import * as Video from 'twilio-video';

if (!environment.production) {
  const { Logger } = Video;
  const logger = Logger.getLogger('twilio-video');
  const originalFactory = logger.methodFactory;
  logger.methodFactory = function (methodName, logLevel, loggerName) {
    const method = originalFactory(methodName, logLevel, loggerName);
    return function (datetime, logLevel, component, message, data) {
      const prefix = '[Twilio - Helper Client]';
      method(prefix, datetime, logLevel, component, message, data);
    };
  };

  switch (environment.video.logLevel) {
    case 'trace':
      logger.setLevel('trace');
      break;
    case 'info':
      logger.setLevel('info');
      break;
    case 'warn':
      logger.setLevel('warn');
      break;
    case 'error':
      logger.setLevel('error');
      break;
    case 'silent':
      logger.setLevel('silent');
      break;
    default:
      logger.setLevel('debug');
      break;
  }
}

if (environment.sentryDsn && environment.useSentry) {
  Sentry.init({
    dsn: environment.sentryDsn,
    environment: environment.production ? 'production' : 'local',
    attachStacktrace: true,
    autoSessionTracking: true,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost:4200', 'https://*.blankmile.com/'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
