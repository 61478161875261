import {
  AssociatedCallPeriod,
  AttachmentDto,
} from 'projects/helper-client/src/api/gen';
import { CallAttachmentType } from 'projects/helper-client/src/app/shared/models/attachment/attachment.models';
import { AttachmentState } from '../attachment/attachment.component';

export class Attachment {
  previewSource: string;
  state?: AttachmentState;
  name: string;
  fileName: string;
  note?: string | null;
  extension: string;
  contentType?: string | null;
  attachmentIdentifier?: string;
  inquiryIdentifier?: string;
  blobUrl?: string | null;
  thumbnailUrl?: string | null;
  annotationBlobUrl?: string | null;
  annotationThumbnailUrl?: string | null;
  createdAt?: string;
  period?: AssociatedCallPeriod;
  type: CallAttachmentType;
  shared?: boolean;
  isInProcess?: boolean;
  processPercent?: number | null;

  static fromRemoteAttachment(remoteAttachment: AttachmentDto): Attachment {
    return {
      previewSource: remoteAttachment.annotationThumbnailUrl
        ? remoteAttachment.annotationThumbnailUrl
        : remoteAttachment.thumbnailUrl,
      state: AttachmentState.NONE,
      name: `${remoteAttachment.fileName}.${remoteAttachment.extension}`,
      fileName: remoteAttachment.fileName,
      note: remoteAttachment.note,
      extension: remoteAttachment.extension,
      contentType: remoteAttachment.contentType,
      attachmentIdentifier: remoteAttachment.attachmentIdentifier,
      inquiryIdentifier: remoteAttachment.inquiryIdentifier,
      blobUrl: remoteAttachment.blobUrl,
      thumbnailUrl: remoteAttachment.thumbnailUrl,
      annotationBlobUrl: remoteAttachment.annotationBlobUrl,
      annotationThumbnailUrl: remoteAttachment.annotationThumbnailUrl,
      createdAt: remoteAttachment.createdAt,
      period: remoteAttachment.period,
      type: this.getSupportedCallAttachmentType(remoteAttachment.contentType),
      shared: remoteAttachment.shared,
      isInProcess: remoteAttachment.isInProcess,
      processPercent: remoteAttachment.processPercent,
    } as Attachment;
  }

  static fromLocalAttachment(
    attachmentWithState: AttachmentWithState
  ): Attachment {
    return {
      previewSource:
        attachmentWithState.attachment?.thumbnailUrl ??
        'assets/images/description.svg',
      state: attachmentWithState.state,

      name: attachmentWithState.name,
      fileName: attachmentWithState.attachment?.fileName,
      extension: attachmentWithState.attachment?.extension,
      attachmentIdentifier:
        attachmentWithState.attachment?.attachmentIdentifier,
      inquiryIdentifier: attachmentWithState.attachment?.inquiryIdentifier,
      blobUrl: attachmentWithState.attachment?.blobUrl,
      thumbnailUrl: attachmentWithState.attachment?.thumbnailUrl,
      annotationBlobUrl: attachmentWithState.attachment?.annotationBlobUrl,
      annotationThumbnailUrl:
        attachmentWithState.attachment?.annotationThumbnailUrl,
      createdAt: attachmentWithState.attachment?.createdAt,
      period: attachmentWithState.attachment?.period,
      type: this.getSupportedCallAttachmentType(
        attachmentWithState.attachment?.contentType
      ),
      shared: attachmentWithState.attachment?.shared,
      isInProcess: attachmentWithState.attachment?.isInProcess,
      processPercent: attachmentWithState.attachment?.processPercent,
    } as Attachment;
  }

  private static getSupportedCallAttachmentType(
    contentType: string
  ): CallAttachmentType {
    if (/image\/*/.test(contentType)) {
      return CallAttachmentType.Image;
    } else if (/application\/pdf/.test(contentType)) {
      return CallAttachmentType.Pdf;
    }

    return CallAttachmentType.Other;
  }
}

export class AttachmentWithState {
  name: string;
  attachment?: AttachmentDto;
  state: AttachmentState;

  constructor(
    name: string,
    attachment: AttachmentDto = null,
    state: AttachmentState
  ) {
    this.name = name;
    this.attachment = attachment;
    this.state = state;
  }
}
