import { Component, Inject } from '@angular/core';
import { Employee } from '../../../model/employee/employee';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DepartmentOption } from '../../../model/employee/department';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UpdateEmployeeRequest } from '../../../model/employee/request/update-employee-request';
import { ProfileService } from '../../../services/api/profile-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-edit-department-dialog',
  templateUrl: './edit-department-dialog.component.html',
  styleUrls: ['./edit-department-dialog.component.scss'],
  providers: [ProfileService],
})
export class EditDepartmentDialogComponent {
  protected readonly loading$ = new BehaviorSubject(false);
  protected employee: Employee;
  protected departmentForm = new FormGroup({
    department: new FormControl('', Validators.required),
  });

  departments = DepartmentOption.getOptions();

  constructor(
    @Inject(MAT_DIALOG_DATA) data: Employee,
    private readonly dialogRef: MatDialogRef<EditDepartmentDialogComponent>,
    private readonly profileService: ProfileService,
    private readonly snackBar: MatSnackBar
  ) {
    this.employee = data;
    this.departmentForm.controls.department.setValue(this.employee.department);
  }

  onClickSaveDepartment() {
    this.loading$.next(true);
    const request = {
      externalIdentifier: this.employee.externalIdentifier,
      firstName: this.employee.firstName,
      lastName: this.employee.lastName,
      eMail: this.employee.email,
      department: this.departmentForm.controls.department.value,
    } as UpdateEmployeeRequest;

    this.profileService.updateProfile(request).subscribe((employee) => {
      this.snackBar.open(
        $localize`Department wurde erfolgreich aktualisiert`,
        $localize`Ok`,
        {
          duration: 3_000,
        }
      );
      this.dialogRef.close(employee);
      this.loading$.next(false);
    });
  }
}
