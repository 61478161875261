import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable()
export class DateFormatService {
  constructor(private readonly datePipe: DatePipe) {}

  private static sameDay(d1: Date, d2: Date): boolean {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  }

  formatDateAttachment(dateString: string): string {
    const today = new Date();
    const yesterday = ((d) => new Date(d.setDate(d.getDate() - 1)))(new Date());
    const date = new Date(dateString);

    const transformedDateText = DateFormatService.sameDay(today, date)
      ? $localize`Heute`
      : DateFormatService.sameDay(yesterday, date)
      ? $localize`Gestern`
      : this.datePipe.transform(date, 'dd.MM.yyyy');

    const transformedTimeText = this.datePipe.transform(date, 'HH:mm');

    return $localize`${transformedDateText} um ${transformedTimeText} Uhr`;
  }
}
