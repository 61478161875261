import { Injectable } from '@angular/core';
import { AudioAnalyzer } from '../diagnostics/audio-analyzer';
import { filter, Subject } from 'rxjs';
import { LocalTrackCtrlService } from './local-track-ctrl.service';

@Injectable({
  providedIn: 'root',
})
export class AudioLevelService {
  private localAudioMonitor: AudioAnalyzer;
  private remoteAudioMonitor: AudioAnalyzer;

  private localAudioVolume = new Subject<number>();
  private remoteAudioVolume = new Subject<number>();

  localAudioVolume$ = this.localAudioVolume.asObservable();
  removeAudioVolume$ = this.remoteAudioVolume.asObservable();
  constructor(readonly localTracks: LocalTrackCtrlService) {
    localTracks.audioTrackUpdated$
      .pipe(filter((x) => !!x?.mediaStreamTrack))
      .subscribe((x) => {
        this.monitorLocalAudio(new MediaStream([x.mediaStreamTrack]));
      });
  }

  monitorLocalAudio(stream: MediaStream): void {
    if (this.localAudioMonitor) {
      this.localAudioMonitor.stop();
    }
    this.localAudioMonitor = new AudioAnalyzer(stream);
    this.localAudioMonitor.getObservable().subscribe(this.localAudioVolume);
  }

  monitorRemoteAudio(stream: MediaStream): void {
    if (this.remoteAudioMonitor) {
      this.remoteAudioMonitor.stop();
    }
    this.remoteAudioMonitor = new AudioAnalyzer(stream);
    this.remoteAudioMonitor.getObservable().subscribe(this.remoteAudioVolume);
  }
}
