import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AudioLevelService } from '../audio-level-service';
import { CallCtrlService } from '../call-ctrl.service';
import { CamState, MicState } from '../device.service';
import { AidarRoom } from '../videochat.service';
import { constants } from '../../../../environments/constants';
import {
  AidarPermissionStatus,
  PermissionService,
} from '../permission.service';
import { DeviceAvailabilityService } from '../device-availability.service';

@Injectable({
  providedIn: 'root',
})
export class DiagnosticsService {
  private _diagnosticsSubject = new BehaviorSubject<Diagnostics>({
    remoteCameraPermissionStatus: AidarPermissionStatus.Granted,
    remoteMicrophonePermissionStatus: AidarPermissionStatus.Granted,
    localCameraPermissionStatus: AidarPermissionStatus.Granted,
    localMicrophonePermissionStatus: AidarPermissionStatus.Granted,
    localCameraDevicesAvailable: true,
    localMicrophoneDevicesAvailable: true,
    remoteParticipantConnected: false,
    remoteConnectionLost: false,
    connectionState: ConnectionState.Connecting,
    localCameraTurnedOn: true,
    localMicrophoneTurnedOn: true,
    localAudioLevel: 0,
    remoteAudioLevel: 0,
    connectedNetworkType: NetworkType.Cellular_2G,
    remoteMicrophoneTurnedOn: true,
    remoteCameraTurnedOn: true,
  });
  private _connectionLostTimer: number;

  // TODO: signalr
  // TODO: Verbindung backend

  public diagnosticsChanged$ = this._diagnosticsSubject.asObservable();

  constructor(
    readonly audioLevels: AudioLevelService,
    readonly callCtrlService: CallCtrlService,
    readonly permissionService: PermissionService,
    readonly availableDevicesService: DeviceAvailabilityService
  ) {
    audioLevels.localAudioVolume$.subscribe((x) => {
      const lastValue = this._diagnosticsSubject.getValue();
      this._diagnosticsSubject.next({
        ...lastValue,
        localAudioLevel: x,
      });
    });
    audioLevels.removeAudioVolume$.subscribe((x) => {
      const lastValue = this._diagnosticsSubject.getValue();
      this._diagnosticsSubject.next({
        ...lastValue,
        remoteAudioLevel: x,
      });
    });
    callCtrlService.subscribeToCamState().subscribe((x) => {
      this.updateCamStatus(x !== CamState.Off);
    });

    callCtrlService.subscribeToMicState().subscribe((x) => {
      this.updateMicStatus(x !== MicState.Muted);
    });

    permissionService.observeChanges().subscribe((x) => {
      const lastValue = this._diagnosticsSubject.getValue();
      this._diagnosticsSubject.next({
        ...lastValue,
        localCameraPermissionStatus: x.currentCamStatus,
        localMicrophonePermissionStatus: x.currentMicStatus,
      });
    });

    availableDevicesService.devicesAvailable$.subscribe((x) => {
      const lastValue = this._diagnosticsSubject.getValue();
      this._diagnosticsSubject.next({
        ...lastValue,
        localMicrophoneDevicesAvailable: x.microphone,
        localCameraDevicesAvailable: x.camera,
      });
    });
  }

  updateDiagnostics(diagnostics: IncomingDiagnostics) {
    const lastValue = this._diagnosticsSubject.getValue();
    this._diagnosticsSubject.next({
      ...lastValue,
      remoteCameraPermissionStatus: diagnostics.cameraPermissionStatus,
      remoteMicrophonePermissionStatus: diagnostics.microphonePermissionStatus,
      remoteCameraTurnedOn: diagnostics.cameraTurnedOn,
      remoteMicrophoneTurnedOn: diagnostics.microphoneTurnedOn,
    });
  }

  setConnectionLostTimeout() {
    this._connectionLostTimer = window.setTimeout(() => {
      this.updateConnectionStatus(true);
    }, constants.participantTimeout);
  }

  public heartbeatReceived(): void {
    if (this._connectionLostTimer) {
      window.clearTimeout(this._connectionLostTimer);
      this._connectionLostTimer = null;
    }
    this.updateConnectionStatus(false);
    this.setConnectionLostTimeout();
  }

  public participantConnected(participantConnected: boolean): void {
    const lastValue = this._diagnosticsSubject.getValue();
    this._diagnosticsSubject.next({
      ...lastValue,
      remoteConnectionLost: false,
      remoteParticipantConnected: participantConnected,
    });
  }

  public isConnectingToRoom(room?: AidarRoom): void {
    const lastValue = this._diagnosticsSubject.getValue();
    let newState = ConnectionState.Connecting;
    if (room?.data?.roomFinishedAt) {
      newState = ConnectionState.RoomClosed;
    } else if (room?.room) {
      newState = ConnectionState.Connected;
    }
    this._diagnosticsSubject.next({
      ...lastValue,
      connectionState: newState,
    });
  }

  private updateConnectionStatus(connectionLost: boolean) {
    const lastValue = this._diagnosticsSubject.getValue();
    if (lastValue) {
      this._diagnosticsSubject.next({
        ...lastValue,
        remoteConnectionLost: connectionLost,
      });
    }
  }

  private updateCamStatus(turnedOn: boolean) {
    const lastValue = this._diagnosticsSubject.getValue();
    if (lastValue) {
      this._diagnosticsSubject.next({
        ...lastValue,
        localCameraTurnedOn: turnedOn,
      });
    }
  }

  private updateMicStatus(turnedOn: boolean) {
    const lastValue = this._diagnosticsSubject.getValue();
    if (lastValue) {
      this._diagnosticsSubject.next({
        ...lastValue,
        localMicrophoneTurnedOn: turnedOn,
      });
    }
  }
}

export interface IncomingDiagnostics {
  cameraPermissionStatus: AidarPermissionStatus;
  microphonePermissionStatus: AidarPermissionStatus;
  cameraTurnedOn: boolean;
  microphoneTurnedOn: boolean;
}

export interface Diagnostics {
  remoteCameraPermissionStatus: AidarPermissionStatus;
  remoteMicrophonePermissionStatus: AidarPermissionStatus;
  localCameraPermissionStatus: AidarPermissionStatus;
  localMicrophonePermissionStatus: AidarPermissionStatus;
  localCameraDevicesAvailable: boolean;
  localMicrophoneDevicesAvailable: boolean;
  connectedNetworkType: NetworkType;
  remoteParticipantConnected: boolean;
  remoteConnectionLost: boolean;
  connectionState: ConnectionState;
  localCameraTurnedOn: boolean;
  localMicrophoneTurnedOn: boolean;
  remoteCameraTurnedOn: boolean;
  remoteMicrophoneTurnedOn: boolean;
  localAudioLevel: number;
  remoteAudioLevel: number;
}

export enum ConnectionState {
  Connecting,
  Connected,
  RoomClosed,
}

export enum NetworkType {
  Unsupported = 0,
  None = 1,
  Cellular_2G = 2,
  Cellular_3G = 3,
  Cellular_4G = 4,
  Cellular_5G = 5,
  Wifi = 6,
}
