<h2 mat-dialog-title i18n>Anruf beendet</h2>
<mat-dialog-content>
  <div *ngIf="inquiryId">
    <app-heading-with-icon-and-action
      i18n-title
      title="Entfernung zum Kunden"
      icon="social_distance"
    >
    </app-heading-with-icon-and-action>
    <div class="distance-container">
      <app-distance-input
        *ngIf="!loading"
        [selection]="selectedDistance"
        (selectionChange)="selectionChanged($event)"
        [enabled]="true"
      ></app-distance-input>
      <mat-progress-spinner
        *ngIf="loading"
        mode="indeterminate"
        diameter="25"
      ></mat-progress-spinner>
      <div class="info-banner">
        <div class="icon">
          <mat-icon>info</mat-icon>
        </div>
        <div class="text" i18n>
          Diese Daten werden später verwendet, um individuelle Statistiken zu
          deiner blankmile Verwendung zu zeigen. <br />
          Außerdem helfen Sie uns, das Benutzererlebnis von blankmile stetig zu
          verbessern.
        </div>
      </div>
    </div>
    <div class="report-container">
      <button
        [disabled]="downloadingPdf$ | async"
        mat-flat-button
        color="accent"
        i18n
        (click)="onDownloadInquirySummary()"
      >
        PDF herunterladen
      </button>
      <button
        [disabled]="sendingPdf$ | async"
        mat-flat-button
        color="accent"
        i18n
        (click)="onSendInquirySummaryToEmployeeMail()"
        *ngIf="employeeMail$ | async"
      >
        PDF an E-Mail senden
      </button>
    </div>
    <div
      class="report-mail-address"
      *ngIf="employeeMail$ | async as mailAddress"
    >
      <p class="report-mail-address-text">
        E-Mail wird an {{ mailAddress }} gesendet
      </p>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    cdkFocusInitial
    class="close-button"
    mat-raised-button
    color="primary"
    mat-dialog-close
    i18n
  >
    Zur Übersicht
  </button>
</mat-dialog-actions>
