import { Department, UpdateEmployee } from '../../../../api/gen';

export class UpdateEmployeeRequest {
  externalIdentifier?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  eMail?: string | null;
  department?: Department;

  static toDto(request: UpdateEmployeeRequest): UpdateEmployee {
    return {
      externalIdentifier: request.externalIdentifier,
      firstName: request.firstName,
      lastName: request.lastName,
      eMail: request.eMail,
      department: request.department,
    } as UpdateEmployee;
  }
}
