import { Component, OnInit } from '@angular/core';
import { DeviceService, DeviceType } from '../../services/device.service';

@Component({
  selector: 'app-setting-dialog',
  templateUrl: './setting-dialog.component.html',
  styleUrls: ['./setting-dialog.component.scss'],
})
export class SettingDialogComponent implements OnInit {
  constructor(private readonly deviceService: DeviceService) {}

  ngOnInit(): void {}

  onSettingsChanged(deviceInfo?: MediaDeviceInfo) {
    this.deviceService.settingsChanged$.next(deviceInfo);
  }
}
