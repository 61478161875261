import { Component, OnInit } from '@angular/core';
import { Observable, of, scan, takeWhile, timer } from 'rxjs';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'app-picture-overlay',
  templateUrl: './picture-overlay.component.html',
  styleUrls: ['./picture-overlay.component.scss'],
  animations: [
    trigger('flashState', [
      state(
        'default',
        style({
          visibility: 'hidden',
        })
      ),
      state(
        'countdown',
        style({
          visibility: 'visible',
        })
      ),
      state(
        'flash',
        style({
          background: 'white',
          opacity: 0.1,
        })
      ),
      transition('default => countdown', animate('400ms ease-out')),
      transition('countdown => flash', animate('400ms ease-in')),
      transition('flash => default', animate('400ms ease-out')),
    ]),
  ],
})
export class PictureOverlayComponent implements OnInit {
  protected countDown$: Observable<number>;

  flashState = 'default';

  ngOnInit(): void {
    this.flashState = 'countdown';
  }

  takeScreenshot(): Observable<number> {
    this.flashState = 'countdown';
    this.countDown$ = timer(0, 1000).pipe(
      scan((acc) => --acc, 4),
      takeWhile((x) => x >= 0)
    );

    this.countDown$.subscribe((value) => {
      if (value === 0) {
        this.flashState = 'flash';
      }
    });

    return this.countDown$;
  }
}
