import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { filter, map, Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PlanSignalingService {
  private hubConnection: signalR.HubConnection;
  private messageStream = new Subject<Message>();

  constructor() {}

  private async connectToHub() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.apiPath + '/plan-hub')
      .withAutomaticReconnect()
      .build();
    await this.hubConnection.start();
    this.addPlanListener();
  }

  public joinTenantIdentifierGroup(tenantIdentifier: string) {
    this.connectToHub().then(() => {
      this.hubConnection
        .invoke(RemoteMethods.connectTenantIdentifier, tenantIdentifier)
        .catch(console.error);
    });
  }

  public closeConnection() {
    this.hubConnection?.stop();
  }

  public onMessage(type: PlanMessageType): Observable<any> {
    return this.messageStream.asObservable().pipe(
      filter((x) => x.type === type),
      map((x) => x.data)
    );
  }

  private addPlanListener(): void {
    this.hubConnection.on(LocalMethods.availableQuotaChanged, (data) => {
      this.messageStream.next(
        new Message(PlanMessageType.AvailableQuotaChanged, data)
      );
    });
  }
}

export enum PlanMessageType {
  AvailableQuotaChanged,
}

class Message {
  constructor(public type: PlanMessageType, public data: any) {}
}

class LocalMethods {
  public static get availableQuotaChanged(): string {
    return 'notifyAvailableQuotaChanged';
  }
}

class RemoteMethods {
  public static get connectTenantIdentifier(): string {
    return 'ConnectTenantIdentifier';
  }
  public static get disconnectTenantIdentifier(): string {
    return 'DisconnectTenantIdentifier';
  }
}
