import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { filter, map, Observable, Subject, takeUntil } from 'rxjs';
import {
  Participant,
  RemoteAudioTrack,
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication,
  RemoteVideoTrack,
} from 'twilio-video';
import { VideoChatService } from '../../services/videochat.service';
import {
  DeviceService,
  DeviceType,
  SinkedHTMLMediaElement,
} from '../../services/device.service';
import { AudioLevelService } from '../../services/audio-level-service';
import { CapabilitiesService } from '../../services/capabilities.service';
import { ZoomState } from '../../services/call-ctrl.service';

@Component({
  selector: 'app-participants',
  styleUrls: ['./participants.component.scss'],
  templateUrl: './participants.component.html',
})
export class ParticipantsComponent implements OnDestroy, AfterViewInit {
  @ViewChild('videoWrapper') videoWrapper: ElementRef;
  @Input()
  public invitationAlreadySent: boolean;
  @Output()
  public videoAvailable = new EventEmitter<boolean>();

  protected isInRoom$: Observable<boolean>;
  private participants: Map<Participant.SID, RemoteParticipant>;
  private elementObserver: ResizeObserver;
  protected participateOnceJoined = false;
  protected roomFinishedAt: string;

  private unsubscribe$ = new Subject<void>();

  // Maybe we should hold those to reuse them for screen rotation
  private currentAudioElement: HTMLAudioElement;
  private currentVideoElement: HTMLVideoElement;

  protected zoomState: ZoomState;
  public ZoomState = ZoomState;

  protected videoTrackEnabled = true;

  constructor(
    private readonly renderer: Renderer2,
    private readonly videoChatService: VideoChatService,
    private readonly deviceService: DeviceService,
    private readonly audioLevelService: AudioLevelService,
    readonly capabilitiesService: CapabilitiesService
  ) {}

  ngAfterViewInit() {
    this.videoChatService.activeRoom$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((activeRoom) => {
        if (!activeRoom) {
          this.clear();
        } else {
          if (activeRoom.room) {
            this.initialize(activeRoom.room.participants);
            // TODO: Maybe create on subject for this
            activeRoom.room
              .on('participantConnected', (participant: RemoteParticipant) =>
                this.add(participant)
              )
              .on('participantDisconnected', (participant: RemoteParticipant) =>
                this.remove(participant)
              );
          }
        }
        this.participateOnceJoined =
          activeRoom?.data?.customerFirstJoin != null;
        this.roomFinishedAt = activeRoom?.data?.roomFinishedAt;
      });
    this.isInRoom$ = this.videoChatService.activeRoom$.asObservable().pipe(
      map((x) => !!x),
      takeUntil(this.unsubscribe$)
    );

    if (this.deviceService.outputSelectionPossible) {
      this.deviceService.settingsChanged$
        .pipe(
          filter((x) => (x.kind as DeviceType) === DeviceType.AUDIO_OUTPUT),
          takeUntil(this.unsubscribe$)
        )
        .subscribe((x) => {
          this.changeAudioOutput();
        });
    }

    this.capabilitiesService.capabilitiesChanged$
      .pipe(
        takeUntil(this.unsubscribe$),
        map((x) => {
          return { width: x.cameraWidth, height: x.cameraHeight, zoom: x.zoom };
        })
      )
      .subscribe((x) => {
        if (this.currentVideoElement) {
          this.zoomState = x.zoom;

          if (this.zoomState === ZoomState.Zoom1) {
            this.currentVideoElement.style.transform = 'scale(2)';
          } else {
            this.currentVideoElement.style.transform = 'scale(1)';
          }
        }
      });
  }

  get participantCount() {
    return this.participants ? this.participants.size : 0;
  }

  get isAlone() {
    return this.participantCount === 0;
  }

  ngOnDestroy(): void {
    this.elementObserver?.disconnect();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private clear() {
    if (this.participants) {
      this.participants.clear();
    }
  }

  private initialize(participants: Map<Participant.SID, RemoteParticipant>) {
    this.participants = participants;
    if (this.participants) {
      this.participants.forEach((participant) =>
        this.registerParticipantEvents(participant)
      );
    }
  }

  private add(participant: RemoteParticipant) {
    if (this.participants && participant) {
      this.participants.set(participant.sid, participant);
      this.registerParticipantEvents(participant);
    }
  }

  private remove(participant: RemoteParticipant) {
    if (this.participants && this.participants.has(participant.sid)) {
      this.participants.delete(participant.sid);
    }
  }

  private registerParticipantEvents(participant: RemoteParticipant) {
    if (participant) {
      // Handle current tracks
      participant.tracks.forEach((publication) => this.subscribe(publication));

      // For new tracks
      participant.on('trackPublished', (publication) =>
        this.subscribe(publication)
      );
      participant.on('trackUnpublished', (publication) => {
        if (publication && publication.track) {
          this.detachRemoteTrack(publication.track);
        }
      });
    }
  }

  private subscribe(publication: RemoteTrackPublication) {
    if (publication.track) this.attachRemoteTrack(publication.track);

    if (publication && publication.on) {
      publication.on('subscribed', (track: RemoteTrack) =>
        this.attachRemoteTrack(track)
      );
      publication.on('unsubscribed', (track: RemoteTrack) =>
        this.detachRemoteTrack(track)
      );
    }
  }

  private changeAudioOutput() {
    const testElement = this.currentAudioElement as SinkedHTMLMediaElement;
    if (
      testElement &&
      this.deviceService.checkIfOutputSelectionIsPossible(testElement)
    ) {
      const currentAudioOutput =
        this.deviceService.getSelectedAudioOutputDevice();
      if (currentAudioOutput) testElement.setSinkId(currentAudioOutput).then();
    }
  }

  private attachRemoteTrack(track: RemoteTrack) {
    if (this.isAttachable(track)) {
      const element = track.attach();
      if (element instanceof HTMLAudioElement) {
        this.currentAudioElement = element;
        if ((track as RemoteAudioTrack).mediaStreamTrack) {
          this.audioLevelService.monitorRemoteAudio(
            new MediaStream([(track as RemoteAudioTrack).mediaStreamTrack])
          );
        }
        this.changeAudioOutput();
        return;
      } else if (element instanceof HTMLVideoElement) {
        this.videoTrackEnabled = track.isEnabled;
        this.videoAvailable.emit(this.videoTrackEnabled);
        track.on('enabled', () => {
          this.videoTrackEnabled = true;
          this.videoAvailable.emit(this.videoTrackEnabled);
        });
        track.on('disabled', () => {
          this.videoTrackEnabled = false;
          this.videoAvailable.emit(this.videoTrackEnabled);
        });
        this.videoWrapper.nativeElement.innerHTML = '';
        this.currentVideoElement = element;
        this.renderer.data['id'] = track.sid;
        this.renderer.setStyle(element, 'height', '100%');
        this.renderer.setStyle(element, 'width', '100%');
        this.currentVideoElement.style.objectFit = 'cover';
        this.renderer.appendChild(this.videoWrapper.nativeElement, element);

        this.elementObserver?.disconnect();
      }
    }
  }

  private detachRemoteTrack(track: RemoteTrack) {
    if (this.isDetachable(track)) {
      track.detach().forEach((el) => el.remove());
    }
    this.elementObserver?.disconnect();
  }

  private isAttachable(
    track: RemoteTrack
  ): track is RemoteAudioTrack | RemoteVideoTrack {
    return (
      !!track &&
      ((track as RemoteAudioTrack).attach !== undefined ||
        (track as RemoteVideoTrack).attach !== undefined)
    );
  }

  private isDetachable(
    track: RemoteTrack
  ): track is RemoteAudioTrack | RemoteVideoTrack {
    return (
      !!track &&
      ((track as RemoteAudioTrack).detach !== undefined ||
        (track as RemoteVideoTrack).detach !== undefined)
    );
  }
}
