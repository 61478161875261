import { AttachmentService as Api } from '../../../api/gen';
import { map, Observable } from 'rxjs';
import { Attachment } from '../../model/attachment/attachment';
import { AttachmentFiltersRequest } from '../../model/attachment/request/attachment-filters-request';
import { Injectable } from '@angular/core';
import { UpdateAttachmentNote } from '../../model/attachment/request/update-attachment-note';

@Injectable()
export class AttachmentService {
  constructor(private readonly attachmentService: Api) {}

  getAttachment(attachmentId: string): Observable<Attachment> {
    return this.attachmentService
      .attachmentAttachmentIdGet(attachmentId)
      .pipe(map((dto) => Attachment.fromDto(dto)));
  }

  getAttachments(
    inquiryId: string,
    attachmentFilters?: AttachmentFiltersRequest
  ): Observable<Attachment[]> {
    return this.attachmentService
      .attachmentInquiryInquiryIdPost(inquiryId, attachmentFilters ?? {})
      .pipe(map((x) => x.map((dto) => Attachment.fromDto(dto))));
  }

  deleteAttachment(attachmentId: string): Observable<Attachment> {
    return this.attachmentService
      .attachmentAttachmentIdDelete(attachmentId)
      .pipe(map((dto) => Attachment.fromDto(dto)));
  }

  updateAttachmentNote(
    attachmentId: string,
    note?: string
  ): Observable<Attachment> {
    const body = { note: note } as UpdateAttachmentNote;
    return this.attachmentService
      .attachmentAttachmentIdNotePut(
        attachmentId,
        UpdateAttachmentNote.toRequest(body)
      )
      .pipe(map((dto) => Attachment.fromDto(dto)));
  }
}
