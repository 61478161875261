import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { RealmService } from './realm.service';

@Injectable({ providedIn: 'root' })
export class InitiatedActionService {
  private static AuthSlug = 'protocol/openid-connect/auth';
  private readonly baseUrl: string;

  constructor(private readonly realmService: RealmService) {
    this.baseUrl = environment.auth.authority;
  }

  public resetPassword(redirectPath: string): void {
    const queryParameters = {
      response_type: 'code',
      client_id: environment.auth.clientId,
      redirect_uri: this.getSanitizedAbsoluteRedirectPath(redirectPath),
      kc_action: 'UPDATE_PASSWORD',
    };

    this.navigateToApplicationInitiatedAction(queryParameters);
  }

  private getSanitizedAbsoluteRedirectPath(redirectPath: string): string {
    const clientRoot = this.realmService.getClientRootForRealm(
      environment.auth.clientRootTemplate
    );
    const redirectPathSanitized = redirectPath.startsWith('/')
      ? redirectPath
      : `/${redirectPath.trim()}`;
    return `${clientRoot}${redirectPathSanitized}`;
  }

  private navigateToApplicationInitiatedAction(queryParameters: {}) {
    const encodedQueryParameters = this.encodeQueryParameters(queryParameters);
    window.location.href = `${
      this.baseUrl
    }/realms/${this.realmService.getRealm()}/${
      InitiatedActionService.AuthSlug
    }?${encodedQueryParameters}`;
  }

  private encodeQueryParameters(queryParameters: {}) {
    const ret: string[] = [];

    for (const [key, value] of Object.entries(queryParameters)) {
      ret.push(
        encodeURIComponent(key) + '=' + encodeURIComponent(value.toString())
      );
    }

    return ret.join('&');
  }
}

export enum KeycloakInitiatedActionStatus {
  Success = 'success',
  Cancelled = 'cancelled',
  Error = 'error',
}
