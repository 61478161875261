import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FileHelperService {
  constructor() {}

  static getFileExtension(filename: string) {
    return filename.substring(filename.lastIndexOf('.') + 1, filename.length);
  }

  static isValidInput(mimeType: string): boolean {
    if (
      Object.values(SupportedFileTypes).includes(
        mimeType as SupportedFileTypes
      ) ||
      mimeType.match(SupportedFileTypes.IMAGE) ||
      mimeType.match(SupportedFileTypes.VIDEO)
    ) {
      return true;
    }
    return false;
  }
}

export enum SupportedFileTypes {
  IMAGE = 'image/*',
  VIDEO = 'video/*',
  PDF = 'application/pdf',
  MS_DOC = 'application/msword',
  MS_DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  MS_XLS = 'application/vnd.ms-excel',
  MS_XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}
